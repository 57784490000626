import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'

import { withSetProp } from '@/services/state/helpers/withSetProp'

export const DevStoreModel = types
  .model('DevStore')
  .props({
    showRemovedModules: types.optional(types.boolean, false),
  })
  .extend(withSetProp)

export interface DevStore extends Instance<typeof DevStoreModel> {}
export interface DevStoreSnapshot extends SnapshotOut<typeof DevStoreModel> {}
