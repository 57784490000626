import avatar00 from './00.png'
import avatar01 from './01.png'
import avatar02 from './02.png'
import avatar03 from './03.png'
import avatar04 from './04.png'
import avatar05 from './05.png'
import avatar06 from './06.png'
import avatar07 from './07.png'
import avatar08 from './08.png'
import avatar09 from './09.png'
import avatar10 from './10.png'
import avatar11 from './11.png'
import avatar12 from './12.png'
import avatar13 from './13.png'
import avatar14 from './14.png'
import avatar15 from './15.png'
import avatar16 from './16.png'
import avatar17 from './17.png'
import avatar18 from './18.png'
import avatar19 from './19.png'
import avatar20 from './20.png'
import avatar21 from './21.png'
import avatarSmesk from './smesk.riv'

const avatarsMap: Record<string | number, string> = {
  0: avatar00,
  1: avatar01,
  2: avatar02,
  3: avatar03,
  4: avatar04,
  5: avatar05,
  6: avatar06,
  7: avatar07,
  8: avatar08,
  9: avatar09,
  10: avatar10,
  11: avatar11,
  12: avatar12,
  13: avatar13,
  14: avatar14,
  15: avatar15,
  16: avatar16,
  17: avatar17,
  18: avatar18,
  19: avatar19,
  20: avatar20,
  21: avatar21,
  smesk: avatarSmesk,
}

export default avatarsMap
