import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(LocalizedFormat)

export function formatDate(date: string | Date | number, opts?: { format?: string; fallback?: string }) {
  const { format = 'lll', fallback } = opts ?? {}

  const dateMoment = dayjs(date)

  if (!date || !dateMoment.isValid()) return fallback ?? null

  return dayjs(date).format(format)
}
