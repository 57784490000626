import { Box, Button, Grid, Group, Modal, Textarea } from '@mantine/core'
import { IconDeviceFloppy } from '@tabler/icons-react'
import { useRef } from 'react'

import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import * as commonClasses from '@/styles/componentCommons.css'

export type ResourceDataContentModalProps = {
  onSubmit: () => Promise<void>
}

export function ResourceDataContentModal(props: CMFormSection & ResourceDataContentModalProps) {
  const dataSnapshot = useRef<string>(props.resourceForm.getValues().data ?? '')

  function restoreData() {
    props.resourceForm.setFieldValue('data', dataSnapshot.current)
  }

  async function submitData() {
    const validation = props.resourceForm.validateField('data')

    if (validation.hasError) {
      return
    }

    await props.onSubmit()

    props.closeModal()
  }

  const isMutating = props.resourceCreateMutation.isPending || props.resourceUpdateMutation.isPending

  return (
    <>
      <Box className={commonClasses.stickyModalFooterContentWrapper}>
        <Grid gutter="xxxl">
          <Grid.Col span={12}>
            <Textarea
              placeholder="Type your content here..."
              withAsterisk
              autosize
              resize="vertical"
              minRows={10}
              flex={1}
              {...props.resourceForm.getInputProps('data')}
            />
          </Grid.Col>
        </Grid>
      </Box>

      <Modal.Header className={commonClasses.stickyModalFooter}>
        <Group gap="xl">
          <Button leftSection={<IconDeviceFloppy />} onClick={submitData} loading={isMutating}>
            Save Content
          </Button>

          {!isMutating && (
            <Button
              size="sm"
              variant="light"
              color="gray"
              onClick={() => {
                restoreData()
                props.resourceForm.setFieldError('data', '')
                props.closeModal()
              }}>
              Cancel
            </Button>
          )}
        </Group>
      </Modal.Header>
    </>
  )
}
