import { Stack } from '@mantine/core'

import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import { FAQTable } from '@/screens/ContentModuleFormScreen/FAQTable'
import { ResourcesTable } from '@/screens/ContentModuleFormScreen/ResourcesTable'

export function OptionalSettingsSection(props: CMFormSection) {
  return (
    <Stack gap="xxxl">
      {/* <Radio.Group
        label={<ComingSoon prefix="Privacy Setting " />}
        description="Select a setting for the whole module. You can select a different setting on a section-by-section basis."
        inputWrapperOrder={['label', 'description', 'input', 'error']}
        {...props.form.getInputProps('access')}
        onChange={e => {
          props.form.setFieldValue('access', e as z.infer<UseContentModuleFormSchema>['access'])
          // updateCM(['access'])() // NOT IMPLEMENTED
        }}>
        <Group gap="xl" align="stretch">
          <RadioCard
            value={props.schema.shape.access.enum.open}
            label="Open"
            description="The user's Sidekick can sit in on the user's conversation about this module."
          />

          <RadioCard
            value={props.schema.shape.access.enum.restricted}
            label="Restricted"
            description="The module contains proprietary and confidential information."
          />
        </Group>
      </Radio.Group> */}

      <FAQTable {...props} type="cm" />

      <ResourcesTable {...props} type="cm" />
    </Stack>
  )
}
