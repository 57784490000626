import { Box, Button, Group, ScrollArea, ThemeIcon } from '@mantine/core'
import { useDebouncedValue, useHover, useMediaQuery } from '@mantine/hooks'
import { IconLogout2 } from '@tabler/icons-react'
import { observer } from 'mobx-react-lite'
import type { PropsWithChildren } from 'react'
import { useLongPress } from 'use-long-press'

import { Consts } from '@/configs/consts'
import { theme } from '@/configs/theme'
import { useGlobalState } from '@/hooks/useGlobalState'
import { ModalStatus } from '@/services/state/UIStore'
import * as classes from '@/styles/Navbar.css'

import { version } from '../../package.json'

type NavbarProps = PropsWithChildren

export const Navbar = observer(function Navbar(props: NavbarProps) {
  const { hovered, ref } = useHover()
  const [hoveredIn] = useDebouncedValue(hovered, 200)
  const [hoveredOut] = useDebouncedValue(hovered, 500)

  const mdBreakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md})`)

  const navbarExpanded = !mdBreakpoint || hoveredIn || hoveredOut

  const { authenticationStore, uiStore } = useGlobalState()

  const { logOut } = authenticationStore

  const versionLongPressProps = useLongPress(() => uiStore.setProp('devModalStatus', ModalStatus.Open), {
    threshold: Consts.isDev ? 1 : 5000,
  })

  async function logout() {
    uiStore.showLoadingOverlay()
    await logOut()
  }

  return (
    <Box ref={ref} className={theme.cx(classes.navbar, navbarExpanded && classes.navbarExpanded)}>
      <ScrollArea className={classes.links} scrollbars="y">
        {props.children}
      </ScrollArea>

      <Group className={classes.footer}>
        {navbarExpanded ? (
          <Button
            size="sm"
            onClick={() => void logout()}
            color="gray"
            variant="light"
            leftSection={<IconLogout2 />}
            classNames={{ root: classes.logoutButton }}>
            Logout
          </Button>
        ) : (
          <ThemeIcon variant="light" color="gray" w={theme.rem(40)} h={theme.rem(40)}>
            <IconLogout2 />
          </ThemeIcon>
        )}

        {navbarExpanded && (
          <Button size="sm" color="gray" variant="transparent" {...versionLongPressProps()}>
            v{version}
          </Button>
        )}
      </Group>
    </Box>
  )
})

// Disabled: https://infinitered.slack.com/archives/C07MY1RQ63S/p1731088788357509
// useHotkeys([['mod+K', openSearchModal]])
// function openSearchModal() {
//   setProp('searchModalStatus', SearchModalStatus.Open)
// }
// <TextInput
//   value=""
//   readOnly
//   size="sm"
//   placeholder="Search"
//   variant="unstyled"
//   className={styles.searchInput}
//   leftSection={<IconSearch className={styles.searchLens} stroke={1.5} />}
//   rightSectionWidth={70}
//   rightSection={<Code className={styles.searchCode}>Ctrl + K</Code>}
//   onClick={openSearchModal}
// />
