import * as Sentry from '@sentry/react'

import { version } from '../../package.json'
import { Consts } from './consts'

export function setupSentry(opts?: Sentry.BrowserOptions) {
  const config: Sentry.BrowserOptions = {
    dsn: 'https://8d5de5d7727cb341bbb33314e4fed299@o4508337499865088.ingest.us.sentry.io/4508337642733568',
    debug: Consts.isDev,
    release: version,
    environment: window.location.hostname.includes('localhost') ? 'localhost' : window.location.hostname.split('.')[0],
    enabled: Consts.isProd,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        const target = hint?.event.target

        if ('innerText' in target && !!target.innerText?.trim()?.length) {
          breadcrumb.message = target.innerText
        }
      }

      return breadcrumb
    },
    ...opts,
  }

  if (config.debug) {
    console.info('Sentry config:', config)
  }

  Sentry.init(config)
}
