import type { StackProps, TextProps, TitleProps } from '@mantine/core'
import { Button, Group, Stack, Text, Title } from '@mantine/core'
import type { IconProps } from '@tabler/icons-react'
import type { ComponentType, ReactNode } from 'react'
import { Link } from 'wouter'

import { theme } from '@/configs/theme'
import type { CommonAction } from '@/utils/componentCommons'
import { getCommonActionPath } from '@/utils/componentCommons'

export interface EmptyStateProps extends StackProps {
  title: string
  description?: ReactNode
  Icon?: ComponentType<IconProps>
  IconProps?: IconProps
  TitleProps?: TitleProps
  DescriptionProps?: TextProps
  size?: 'lg' | 'md' | 'sm'
  action?: CommonAction
}

export function EmptyState(props: EmptyStateProps) {
  const {
    title,
    description,
    Icon,
    size = 'md',
    action,
    IconProps,
    TitleProps,
    DescriptionProps,
    ...StackProps
  } = props

  return (
    <Stack align="center" justify="center" {...stackPropsMap[size]} {...StackProps}>
      {!!Icon && <Icon color={theme.colors.gray['4']} {...iconPropsMap[size]} {...IconProps} />}

      <Title c="dimmed" ta="center" {...titlePropsMap[size]} {...TitleProps}>
        {title}
      </Title>

      {!!description && (
        <Text w="100%" maw={theme.rem(540)} ta="center" c="dimmed" {...descriptionPropsMap[size]} {...DescriptionProps}>
          {description}
        </Text>
      )}

      {!!action && (
        <Group justify="center" mt="xl">
          <Button component={action.to ? Link : undefined} to={getCommonActionPath(action.to)}>
            {action.label}
          </Button>
        </Group>
      )}
    </Stack>
  )
}

const iconPropsMap: Record<Required<EmptyStateProps>['size'], IconProps> = {
  lg: { size: 80 },
  md: { size: 60 },
  sm: { size: 50 },
}

const titlePropsMap: Record<Required<EmptyStateProps>['size'], TitleProps> = {
  lg: { order: 2, fw: 700 },
  md: { order: 5, fw: 600 },
  sm: { order: 6, fw: 500 },
}

const descriptionPropsMap: Record<Required<EmptyStateProps>['size'], TextProps> = {
  lg: { size: 'lg' },
  md: { size: 'md' },
  sm: { size: 'md' },
}

const stackPropsMap: Record<Required<EmptyStateProps>['size'], StackProps> = {
  lg: { py: 'xxl' },
  md: { py: 'xl' },
  sm: { py: 'xs' },
}
