import { Box, Button, Group, Modal, Paper, Stack, Text, TypographyStylesProvider } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { marked } from 'marked'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { theme } from '@/configs/theme'
import * as commonClasses from '@/styles/componentCommons.css'
import * as styles from '@/styles/ContentModuleScreen.css'
import { isMarkdown } from '@/utils/strings'

const VISIBILITY_TRANSITION_DURATION = 300

type ModalContent = {
  data?: string
  title?: string
  subtitle?: string
}

type OpenModalArgs = ModalContent & { opts?: { markdown?: boolean } }

export type ContentDataModal = {
  openModal: (content: OpenModalArgs) => void
  closeModal: () => void
}

export const ContentDataModal = forwardRef<ContentDataModal, any>(function ContentDataModal(_props, ref) {
  const closeTimeout = useRef<NodeJS.Timeout>(undefined)

  const [isOpen, { open, close }] = useDisclosure()
  const [modalContent, setModalContent] = useState<ModalContent | null>(null)

  function openModal(content: OpenModalArgs) {
    const { opts, data, ...restContent } = content

    clearTimeout(closeTimeout.current)
    closeTimeout.current = undefined

    if (!!data && opts?.markdown && isMarkdown(data)) {
      const parsedData = marked(data, { async: false })
      setModalContent({ ...restContent, data: parsedData })
    } else {
      setModalContent({ ...restContent, data })
    }

    open()
  }

  function closeModal() {
    close()

    closeTimeout.current = setTimeout(() => setModalContent(null), VISIBILITY_TRANSITION_DURATION)
  }

  useImperativeHandle(ref, () => ({ openModal, closeModal }))

  return (
    <Modal
      opened={isOpen}
      centered
      trapFocus
      radius="md"
      padding="lg"
      closeOnEscape
      closeOnClickOutside
      withCloseButton={false}
      size={theme.rem(1200)}
      onClose={closeModal}
      transitionProps={{ duration: VISIBILITY_TRANSITION_DURATION }}
      title={
        modalContent?.title && (
          <Stack gap={0}>
            <Text size="xl" fw={600}>
              {modalContent?.title}
            </Text>

            {!!modalContent?.subtitle && (
              <Text size="md" c="dimmed">
                {modalContent.subtitle}
              </Text>
            )}
          </Stack>
        )
      }
      classNames={{ body: commonClasses.stickyModalFooterBody }}>
      <Box className={commonClasses.stickyModalFooterContentWrapper}>
        <Paper bg={theme.lighten(theme.colors.primaryColors[1], 0.7)} radius="sm" p="md" pb="xl" shadow="0">
          <TypographyStylesProvider className={styles.typographyProvider}>
            <div dangerouslySetInnerHTML={{ __html: modalContent?.data ?? '' }} />
          </TypographyStylesProvider>
        </Paper>
      </Box>

      <Modal.Header className={commonClasses.stickyModalFooter}>
        <Group gap="xl">
          <Button size="sm" variant="light" color="gray" onClick={closeModal}>
            Close
          </Button>
        </Group>
      </Modal.Header>
    </Modal>
  )
})
