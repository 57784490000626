import type { RouteDef, RouteNames, RouteRedirectResponse, RouteRenderConditionResponse } from '@/utils/navigation'
import { getObjectEntries } from '@/utils/objects'

export function logCreateRouteStack(enabled: boolean, routes: Record<RouteNames, Omit<RouteDef, 'routes'>>) {
  if (!enabled) return

  const defs = getObjectEntries(routes)

  console.group('Route Stack')
  defs.forEach(([name, def]) => {
    const conditionString =
      typeof def.renderConditionFn === 'undefined' ? 'always' : `\n\n${def.renderConditionFn.toString().trim()}`

    console.groupCollapsed(name)
    console.log(`%c- Path:`, 'font-weight:bold;', def.path)
    console.log(
      `%c- Component:`,
      'font-weight:bold;',
      // @ts-expect-error -- Component might not be difined but it's okay since we're just logging.
      def.component?.name ?? def.component?.type?.name ?? def.component,
    )
    console.log(`%c- Render Condition:`, 'font-weight:bold;', conditionString)
    console.groupEnd()
  })
  console.groupEnd()
}

export function logRoute(
  enabled: boolean,
  name: RouteNames,
  path: string,
  component: RouteDef['component'],
  shouldRender: RouteRenderConditionResponse,
  shouldRedirect: RouteRedirectResponse,
) {
  if (!enabled) return (_action: string) => undefined

  return function (action: string) {
    console.groupCollapsed(`Route: ${name} - ${action}`)
    console.log(`%c- Path:`, 'font-weight:bold;', path)
    console.log(
      `%c- Component:`,
      'font-weight:bold;',
      // @ts-expect-error -- Component might not be difined but it's okay since we're just logging.
      component?.name ?? component?.type?.name ?? component,
    )
    if (typeof shouldRender === 'boolean') {
      console.log(`%c- Will Render:`, 'font-weight:bold;', shouldRender ? 'Yes' : 'No')
    }

    if (typeof shouldRender === 'object') {
      console.log(`%c- Will Redirect:`, 'font-weight:bold;', shouldRender.redirectTo)
    } else if (typeof shouldRedirect === 'object') {
      console.log(`%c- Will Redirect:`, 'font-weight:bold;', shouldRedirect.redirectTo)
    }

    console.groupEnd()
  }
}

export function logForm(formName: string, formValues: Record<string, unknown>) {
  console.groupCollapsed(`Form: ${formName}`)
  console.table(formValues)
  console.groupEnd()
}
