import { Box, Button, Grid, Group, Modal, Textarea } from '@mantine/core'
import { IconDeviceFloppy } from '@tabler/icons-react'

import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import * as commonClasses from '@/styles/componentCommons.css'

export function AddDataContentModal(props: CMFormSection) {
  async function createCM() {
    const validation = props.moduleForm.validateField('content.text')

    if (validation.hasError) {
      return
    }

    const values = props.moduleForm.getValues()

    if (props.taskId) {
      await props.contentUpdateMutation?.mutateAsync({
        task_id: props.taskId,
        text: values.content.text,
      })
    } else {
      await props.cmCreateMutation?.mutateAsync({ content: values.content.text })
    }

    props.closeModal()
  }

  const isMutating = props.cmCreateMutation?.isPending

  return (
    <>
      <Box className={commonClasses.stickyModalFooterContentWrapper}>
        <Grid gutter="xxxl">
          <Grid.Col span={12}>
            <Textarea
              placeholder="Type your content here..."
              withAsterisk
              autosize
              resize="vertical"
              minRows={10}
              flex={1}
              disabled={isMutating}
              {...props.moduleForm.getInputProps('content.text')}
            />
          </Grid.Col>
        </Grid>
      </Box>

      <Modal.Header className={commonClasses.stickyModalFooter}>
        <Group gap="xl">
          <Button leftSection={<IconDeviceFloppy />} onClick={createCM} loading={isMutating}>
            Save Content
          </Button>

          {!isMutating && (
            <Button size="sm" variant="light" color="gray" onClick={() => props.closeModal()}>
              Cancel
            </Button>
          )}
        </Group>
      </Modal.Header>
    </>
  )
}
