import { Box, Button, Divider, Group, Menu, Modal, Stack, Text } from '@mantine/core'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { useGlobalState } from '@/hooks/useGlobalState'
import { ModalStatus } from '@/services/state/UIStore'
import * as classes from '@/styles/DevModal.css'
import { clear } from '@/utils/storage'

export const DevModal = observer(function DevModal() {
  const { uiStore, authenticationStore, reset, configStore, devStore } = useGlobalState()
  const { devModalStatus, setProp: setUIProp } = uiStore
  const { setProp: setDevProp, showRemovedModules } = devStore

  const [renderWhoops, setRenderWoops] = useState(false)

  function closeModal() {
    setUIProp('devModalStatus', ModalStatus.Closed)
  }

  async function portalReset() {
    uiStore.showLoadingOverlay()

    if ('serviceWorker' in navigator) {
      const regs = await navigator.serviceWorker.getRegistrations()

      for (const registration of regs) {
        await registration.unregister()
      }

      const cacheNames = await caches.keys()

      for (const key of cacheNames) {
        await caches.delete(key)
      }
    }

    await reset()
    clear()

    uiStore.hideLoadingOverlay()
    window.location.reload()
  }

  function userMakeSponsor() {
    authenticationStore.setProp('user', { ...authenticationStore.user!, isSponsor: true })
  }

  function logUserInfo() {
    console.log(authenticationStore.user)
  }

  function logConfig() {
    console.log(toJS(configStore))
  }

  if (renderWhoops) {
    // @ts-expect-error - we want to do something stupid here
    whoops('Unhandled Render Exception')
  }

  return (
    <Modal
      size="lg"
      opened={devModalStatus === ModalStatus.Open}
      onClose={closeModal}
      radius="lg"
      classNames={classes.modal}
      title="Development Options">
      <Stack gap="xxl">
        <Box>
          <Text size="sm" fw={600} tt="uppercase">
            Portal Options
          </Text>
          <Divider mt="xs" mb="md" color="gray.5" />
          <Group>
            <Button size="compact-sm" onClick={portalReset}>
              Reset Portal
            </Button>

            <Button
              size="compact-sm"
              onClick={() => {
                setTimeout(() => setRenderWoops(true), 100)
                // @ts-expect-error - we want to do something stupid here
                whoops('Unhandled Exception')
              }}>
              Trigger Exception
            </Button>
          </Group>
        </Box>

        <Box>
          <Text size="sm" fw={600} tt="uppercase">
            User Options
          </Text>
          <Divider mt="xs" mb="md" color="gray.5" />
          <Group>
            <Button size="compact-sm" onClick={userMakeSponsor}>
              Make Me a Sponsor
            </Button>

            <Button size="compact-sm" onClick={logUserInfo}>
              Console Log User Info
            </Button>

            <Button size="compact-sm" onClick={logConfig}>
              Console Log Config
            </Button>
          </Group>
        </Box>

        <Box>
          <Text size="sm" fw={600} tt="uppercase">
            Content Options
          </Text>
          <Divider mt="xs" mb="md" color="gray.5" />
          <Group>
            <Button size="compact-sm" onClick={() => setDevProp('showRemovedModules', !showRemovedModules)}>
              {showRemovedModules ? 'Hide' : 'Show'} Removed Content Modules
            </Button>
          </Group>
        </Box>

        <Box>
          <Text size="sm" fw={600} tt="uppercase">
            API Options
          </Text>
          <Divider mt="xs" mb="md" color="gray.5" />
          <Group>
            <Menu withArrow arrowPosition="center">
              <Menu.Target>
                <Button size="compact-sm">Select API Environment</Button>
              </Menu.Target>

              <Menu.Dropdown>
                {['dev', 'test', 'stag', 'intrl'].map(env => (
                  <Menu.Item
                    key={env}
                    onClick={() => {
                      configStore.saveConfigOverride({
                        VITE_SERVER_BASE_URL: `https://${env}.sme-server.sidekickplatform.com`,
                      })
                      window.location.reload()
                    }}>
                    <Text style={{ whiteSpace: 'nowrap' }}>
                      <Text fw={700} component="span">
                        {env}
                      </Text>
                      <Text c="dimmed" component="span">
                        .sme-server.sidekickplatform.com
                      </Text>
                    </Text>
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Box>
      </Stack>
    </Modal>
  )
})
