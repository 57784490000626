// https://mantine.dev/colors-generator/?color=53e6f6
const cyan = [
  '#deffff',
  '#cafaff',
  '#9bf2fb',
  '#68eaf8',
  '#40e3f5',
  '#23dff4',
  '#00ddf4',
  '#00c5d9',
  '#00afc2',
  '#0097aa',
] as const

// https://mantine.dev/colors-generator/?color=444c95
const indigo = [
  '#eff2ff',
  '#dde0f3',
  '#bbbee0',
  '#959bce',
  '#767cbe',
  '#6169b5',
  '#575fb1',
  '#474f9c',
  '#3e478c',
  '#323c7d',
] as const

// https://mantine.dev/colors-generator/?color=439ce0
const blue = [
  '#e2f8ff',
  '#d0ebfd',
  '#a7d2f4',
  '#78b9ea',
  '#52a4e2',
  '#3997de',
  '#2790dd',
  '#147cc5',
  '#006eb2',
  '#00609f',
] as const

export const colors = { cyan, indigo, blue }
