import { type DeepPartial, type RecursiveKeyOf } from '@/types/helpers'

export function getObjectKeys<Obj extends object>(obj: Obj): Array<keyof Obj> {
  return Object.keys(obj) as Array<keyof Obj>
}

export function getObjectValues<Obj extends object>(obj: Obj): Array<Obj[keyof Obj]> {
  return Object.values(obj) as Array<Obj[keyof Obj]>
}
export function getObjectEntries<Obj extends object>(obj: Obj): Array<[keyof Obj, Obj[keyof Obj]]> {
  return Object.entries(obj) as Array<[keyof Obj, Obj[keyof Obj]]>
}

export function deleteObjectAttribute<O extends object, A extends keyof O>(object: O, attr: A): Omit<O, A> {
  if (attr in object) {
    const { [attr]: _, ...newObject } = object
    return newObject
  }

  return object
}

export function excludePathsFromObject<T extends object>(obj: T, excludes: Array<RecursiveKeyOf<T>>): DeepPartial<T> {
  type ExcludePath = (typeof excludes)[0]

  const excludesSet = new Set(excludes)

  if (excludesSet.size === 0) return obj as DeepPartial<T>

  function filterObject(subObj: any, accObj: any, path?: string) {
    for (const key in subObj) {
      const fullPath = (path ? `${path}.${key}` : key) as ExcludePath

      if (excludesSet.has(fullPath)) continue

      if (typeof subObj[key] === 'object' && !Array.isArray(subObj[key])) {
        accObj[key] = {}
        filterObject(subObj[key], accObj[key], fullPath)
      } else {
        accObj[key] = subObj[key]
      }
    }

    return accObj
  }

  return filterObject(obj, {})
}

export function pickFromObject<T extends object, K extends keyof T>(obj: T, keys: Array<K>): Pick<T, K> {
  const newObj = {} as Pick<T, K>

  for (const key of keys) {
    newObj[key] = obj[key]
  }

  return newObj
}

export function removeNullishValuesFromObject<T extends object>(obj: T): Partial<T> {
  const newObj = {} as Partial<T>

  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined) {
      newObj[key] = obj[key]
    }
  }

  return newObj
}
