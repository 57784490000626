import type { MantineSpacing } from '@mantine/core'
import { Box, Group, Stack, Text } from '@mantine/core'
import type { ReactNode } from 'react'

import { theme } from '@/configs/theme'
import * as classes from '@/styles/TableContentGrid.css'
import type { StringWithAutocomplete } from '@/types/helpers'

type TableContentGridRow = {
  id?: string
  heading: ReactNode
  content: ReactNode
  headingVerticalAlign?: 'top' | 'middle'
}

type TableContentGridProps = {
  rows: TableContentGridRow[]
  spacing?: MantineSpacing
  headingFlexWidth?: StringWithAutocomplete<'auto'>
}

export function TableContentGrid(props: TableContentGridProps) {
  const { rows, spacing = 'lg', headingFlexWidth = 'auto' } = props

  return (
    <Box>
      {rows.map((row, index) => (
        <Group key={row.id || index} className={classes.row} gap={0} wrap="nowrap" justify="flex-start" align="stretch">
          <Stack
            p={spacing}
            justify={row.headingVerticalAlign === 'top' ? 'flex-start' : 'center'}
            align="center"
            flex={`0 0 ${headingFlexWidth}`}
            className={theme.cx(classes.headingCell)}>
            {typeof row.heading === 'string' ? (
              <Text size="sm" fw={600} ta="center">
                {row.heading}
              </Text>
            ) : (
              row.heading
            )}
          </Stack>

          <Box p={spacing} flex="1 1 auto" miw={0}>
            {typeof row.content === 'string' ? <Text>{row.content}</Text> : row.content}
          </Box>
        </Group>
      ))}
    </Box>
  )
}
