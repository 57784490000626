import * as Sentry from '@sentry/react'
import merge from 'deepmerge'
import { reaction } from 'mobx'
import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'

import type {
  CheckUserResponse,
  LoginResponse,
  LoginResponseError,
  SafeRequestResponse,
} from '@/services/api/api.types'
import { withRootStore } from '@/services/state/helpers/withRootStore'
import { withSetProp } from '@/services/state/helpers/withSetProp'

export const AuthenticationStoreModel = types
  .model('AuthenticationStore')
  .props({
    ready: types.optional(types.boolean, false),
    auth: types.maybe(types.frozen<LoginResponse['authenticationResult']>()),
    user: types.maybe(types.frozen<CheckUserResponse>()),
  })
  .extend(withSetProp)
  .extend(withRootStore)
  .volatile<AuthenticationStoreVolatileProps>(() => ({
    refreshTokenTimer: undefined,
  }))
  .views(self => ({
    get isAuthenticated() {
      return !!self.auth?.idToken
    },
  }))
  .actions(self => ({
    reset() {
      Sentry.setUser(null)
      self.auth = undefined
      self.user = undefined
      clearTimeout(self.refreshTokenTimer)
      self.refreshTokenTimer = undefined
    },
  }))
  .actions(self => ({
    async logIn(params: {
      email: string
      password: string
    }): Promise<{ ok: boolean; message: string; code?: LoginResponseError['code'] }> {
      const response = await self.rootStore.api.safeRequest<typeof self.rootStore.api.login, LoginResponseError>(
        self.rootStore.api.login,
        {
          username: params.email,
          password: params.password,
        },
      )

      return this.processAuthResponse(response)
    },
    async refresh() {
      if (!self.auth?.refreshToken) {
        void self.rootStore.reset()
        self.setProp('ready', true)
        return
      }

      const response = await self.rootStore.api.safeRequest<typeof self.rootStore.api.refreshToken, LoginResponseError>(
        self.rootStore.api.refreshToken,
        { refresh_token: self.auth.refreshToken },
      )
      const processedResponse = await this.processAuthResponse(response)

      if (!processedResponse.ok) {
        void self.rootStore.reset()
      }
      self.setProp('ready', true)
    },
    async logOut() {
      await self.rootStore.api.safeRequest(self.rootStore.api.logout, { access_token: self.auth?.accessToken! })
      await self.rootStore.reset()
    },
    startRefreshTokenTimer() {
      if (!self.auth?.expiresIn) return

      self.refreshTokenTimer = setTimeout(() => void this.refresh(), self.auth.expiresIn * 900)
    },
    async processAuthResponse(
      response: SafeRequestResponse<LoginResponse, LoginResponseError>,
    ): Promise<{ ok: boolean; message: string; code?: LoginResponseError['code'] }> {
      if (response.ok) {
        if (response.data.authenticationResult?.idToken) {
          self.rootStore.api.setAuthToken(response.data.authenticationResult.idToken)

          const userResponse = await self.rootStore.api.safeRequest(self.rootStore.api.checkUser)

          if (userResponse.ok) {
            Sentry.setUser({
              id: userResponse.data.userId,
              ...userResponse.data,
            })
          }

          if (!userResponse.ok || !userResponse.data.access.sme) {
            const message = !userResponse.ok && userResponse.data.message

            return {
              ok: false,
              message: message || 'You are not authorized to use this application.',
            }
          } else {
            self.setProp('user', userResponse.data)
          }

          self.setProp('auth', merge(self.auth ?? {}, response.data.authenticationResult)) // merging because refresh token response does not include new refresh token

          this.startRefreshTokenTimer()

          return { ok: true, message: 'Success' }
        } else {
          return { ok: false, message: 'Something went wrong.' }
        }
      } else {
        return {
          ok: false,
          message: response.data.message ?? response.error?.message ?? 'Something went wrong.',
          code: response.data.code,
        }
      }
    },
  }))
  .actions(self => ({
    afterAttach() {
      reaction(
        () => self.rootStore.configStore.ready,
        ready => ready && void self.refresh(),
      )
    },
    beforeDestroy() {
      if (self.refreshTokenTimer) {
        clearTimeout(self.refreshTokenTimer)
        self.refreshTokenTimer = undefined
      }
    },
  }))

export interface AuthenticationStoreVolatileProps {
  refreshTokenTimer: NodeJS.Timeout | undefined
}
export interface AuthenticationStore extends Instance<typeof AuthenticationStoreModel> {}
export interface AuthenticationStoreSnapshot extends SnapshotOut<typeof AuthenticationStoreModel> {}
