import type { ActionIconProps } from '@mantine/core'
import { ActionIcon, Box, Button, Group, Tooltip } from '@mantine/core'
import type { IconProps } from '@tabler/icons-react'
import {
  IconArchive,
  IconCircleX,
  IconDotsVertical,
  IconEye,
  IconHelp,
  IconPencil,
  IconPlayerPlay,
  IconSearch,
  IconTrash,
  IconWorldShare,
  IconZoomCancel,
} from '@tabler/icons-react'
import merge from 'deepmerge'
import type { MRT_Row, MRT_RowData, MRT_TableInstance, MRT_TableOptions } from 'mantine-react-table'
import { MantineReactTable, MRT_GlobalFilterTextInput, useMantineReactTable } from 'mantine-react-table'
import type { ComponentType, ReactNode } from 'react'
import { useMemo } from 'react'

import type { EmptyStateProps } from '@/components/EmptyState'
import { EmptyState } from '@/components/EmptyState'
import { theme } from '@/configs/theme'
import * as classes from '@/styles/Table.css'
import type { MarkOptional, TypeOrTypeFn } from '@/types/helpers'

const rowActionsIconRegistry = {
  edit: IconPencil,
  view: IconEye,
  delete: IconTrash,
  archive: IconArchive,
  demo: IconPlayerPlay,
  menu: IconDotsVertical,
  changeStatus: IconWorldShare,
}

const rowActionsIconStrokeMap: Record<keyof typeof rowActionsIconRegistry, number> = {
  edit: 2,
  view: 1.5,
  delete: 1.5,
  archive: 1.5,
  demo: 1.5,
  menu: 2,
  changeStatus: 2,
}

const rowActionsIconColorMap: Record<keyof typeof rowActionsIconRegistry, string> = {
  edit: 'indigo',
  view: 'blue',
  delete: 'red.7',
  archive: 'orange.7',
  demo: 'blue',
  menu: 'gray',
  changeStatus: 'gray',
}

const toolBarActionsLabelMap = {
  new: 'New',
}

export interface TableProps<DataT extends MRT_RowData> extends MRT_TableOptions<DataT> {
  entity?: { singular: string; plural: string; Icon?: ComponentType<IconProps> }
  rowActionsInclude?: TableRowActionsProps<DataT>['include']
  rowActionsTooltipLabels?: TableRowActionsProps<DataT>['tooltipLabels']
  rowActionsButtonProps?: TableRowActionsProps<DataT>['actionButtonProps']
  onRowActionClick?: TableRowActionsProps<DataT>['onActionClick']
  toolbarActionsInclude?: TableTopToolbarProps<DataT>['include']
  onToolbarActionClick?: TableTopToolbarProps<DataT>['onActionClick']
  EmptyStateProps?: TableEmptyRowsFallbackProps<DataT>['EmptyStateProps']
}

export function Table<DataT extends MRT_RowData>(props: TableProps<DataT>) {
  const {
    data,
    columns,
    rowActionsInclude,
    onRowActionClick,
    renderRowActions,
    entity,
    rowActionsButtonProps,
    onToolbarActionClick,
    toolbarActionsInclude,
    EmptyStateProps,
    rowActionsTooltipLabels,
    ...additionalConfig
  } = props

  const config = useMemo(
    () =>
      merge.all<Omit<MRT_TableOptions<DataT>, 'data' | 'columns'>>([
        {
          layoutMode: 'grid',
          enableBottomToolbar: false,
          enableColumnActions: false,
          enableColumnFilters: false,
          enablePagination: false,
          enableSorting: false,
          enableHiding: false,
          enableDensityToggle: false,
          enableFullScreenToggle: false,
          enableGlobalFilter: true,
          positionGlobalFilter: 'left',
          initialState: { density: 'md', showGlobalFilter: true },
          mantinePaperProps: { shadow: 'none', withBorder: false, className: classes.paper },
          mantineTableProps: { highlightOnHover: true },
          positionActionsColumn: 'last',
          displayColumnDefOptions: {
            'mrt-row-actions': {
              header: undefined,
              grow: false,
              size: 140,
            },
            'mrt-row-drag': {
              header: undefined,
            },
          },
          renderTopToolbar: ({ table }) => (
            <TableTopToolbar
              table={table}
              entity={entity}
              include={toolbarActionsInclude}
              onActionClick={onToolbarActionClick}
            />
          ),
          renderEmptyRowsFallback: ({ table }) => (
            <TableEmptyRowsFallback table={table} entity={entity} EmptyStateProps={EmptyStateProps} />
          ),
        },
        rowActionsInclude
          ? {
              enableRowActions: true,
              renderRowActions: props => (
                <TableRowActions
                  {...props}
                  include={rowActionsInclude}
                  tooltipLabels={rowActionsTooltipLabels}
                  onActionClick={onRowActionClick}
                  actionButtonProps={rowActionsButtonProps}
                />
              ),
            }
          : {},
        renderRowActions ? { enableRowActions: true, renderRowActions } : {},
        additionalConfig,
      ]),
    [additionalConfig],
  )

  const table = useMantineReactTable({
    data,
    columns,
    ...config,
  })

  return <MantineReactTable table={table} />
}

type TableRowActionsProps<DataT extends MRT_RowData> = Parameters<
  NonNullable<TableProps<DataT>['renderRowActions']>
>[0] & {
  include?: TypeOrTypeFn<Array<keyof typeof rowActionsIconRegistry> | undefined, [MRT_Row<DataT>]>
  actionButtonProps?: TypeOrTypeFn<
    Partial<Record<keyof typeof rowActionsIconRegistry, ActionIconProps>> | undefined,
    [MRT_Row<DataT>]
  >
  onActionClick?: (action: keyof typeof rowActionsIconRegistry, row: MRT_Row<DataT>) => void
  tooltipLabels?: TypeOrTypeFn<
    Partial<Record<keyof typeof rowActionsIconRegistry, string | undefined>> | undefined,
    [MRT_Row<DataT>]
  >
}

export function TableRowActions<DataT extends MRT_RowData>(props: TableRowActionsProps<DataT>) {
  const { onActionClick, row } = props

  const include = (typeof props.include === 'function' ? props.include(row) : props.include) ?? []
  const actionButtonProps =
    typeof props.actionButtonProps === 'function' ? props.actionButtonProps(row) : props.actionButtonProps
  const tooltipLabels = typeof props.tooltipLabels === 'function' ? props.tooltipLabels(row) : props.tooltipLabels

  if (!include.length) return null

  return (
    <Group gap="xs" justify="flex-end" flex="1 1 100%" wrap="nowrap">
      {include.map(a => {
        const tooltipLabel = tooltipLabels?.[a]

        return (
          <TableRowActionButton
            key={a}
            action={a}
            tooltipLabel={tooltipLabel}
            onClick={() => onActionClick?.(a, row)}
            actionButtonProps={actionButtonProps?.[a]}
          />
        )
      })}
    </Group>
  )
}

export function TableRowActionButton(props: {
  action: keyof typeof rowActionsIconRegistry
  actionButtonProps?: ActionIconProps
  tooltipLabel?: string
  onClick?: () => void
}) {
  const Icon = rowActionsIconRegistry[props.action]
  const color = rowActionsIconColorMap[props.action]
  const stroke = rowActionsIconStrokeMap[props.action]

  return (
    <Tooltip
      withArrow
      arrowSize={12}
      position="top"
      offset={8}
      color={props.actionButtonProps?.disabled ? 'gray' : color}
      label={props.tooltipLabel}
      disabled={!props.tooltipLabel}>
      <ActionIcon
        variant="subtle"
        color={color}
        {...props.actionButtonProps}
        className={theme.cx(classes.actionButton, props.actionButtonProps?.className)}
        onClick={props.onClick}>
        <Icon className={classes.rowActionIcon} stroke={stroke} />
      </ActionIcon>
    </Tooltip>
  )
}

type TableTopToolbarProps<DataT extends MRT_RowData> = {
  entity: TableProps<DataT>['entity']
  table: MRT_TableInstance<DataT>
  include?: Array<'new'>
  onActionClick?: (action: 'new') => void
}

export function TableTopToolbar<DataT extends MRT_RowData>(props: TableTopToolbarProps<DataT>) {
  const { entity, include = [], onActionClick, table } = props

  return (
    <Group justify="space-between" mb={theme.spacing.xl}>
      {table.options.enableGlobalFilter ? (
        <MRT_GlobalFilterTextInput
          table={props.table}
          size="sm"
          placeholder={`Search ${entity ? entity.plural : ''}`}
          classNames={{ root: classes.globalFilter, input: classes.globalFilterInput }}
          leftSection={<IconSearch stroke={1.5} />}
        />
      ) : (
        <Box />
      )}

      {include.length ? (
        <Group>
          {include.map(a => (
            <Button key={a} onClick={() => onActionClick?.(a)}>
              {`${toolBarActionsLabelMap[a]} ${entity ? entity.singular : ''}`}
            </Button>
          ))}
        </Group>
      ) : (
        <Box />
      )}
    </Group>
  )
}

type TableEmptyRowsFallbackProps<DataT extends MRT_RowData> = {
  entity: TableProps<DataT>['entity']
  table: MRT_TableInstance<DataT>
  EmptyStateProps?: MarkOptional<EmptyStateProps, 'title'>
}

export function TableEmptyRowsFallback<DataT extends MRT_RowData>(props: TableEmptyRowsFallbackProps<DataT>) {
  const { entity, table, EmptyStateProps } = props

  const { globalFilter } = table.getState()

  return (
    <EmptyState
      size="sm"
      Icon={globalFilter ? IconZoomCancel : (entity?.Icon ?? IconCircleX)}
      title={`No ${entity ? entity.plural : 'Records'} to Display`}
      description={globalFilter ? 'Reset the filter or try a different search term.' : undefined}
      {...EmptyStateProps}
    />
  )
}

export const HeaderCellWithTooltip = (props: { column: any; tooltip: string; header?: ReactNode }) => {
  return (
    <Tooltip withArrow arrowSize={12} position="top" offset={8} label={props.tooltip}>
      <Group gap={theme.rem(4)} justify="flex-start" align="center">
        {props.header ?? props.column.columnDef.header}
        <IconHelp stroke={1.5} size={20} />
      </Group>
    </Tooltip>
  )
}
