import { getRoot, type IStateTreeNode } from 'mobx-state-tree'

import { type RootStore } from '@/services/state/RootStore'

export function withRootStore<T extends IStateTreeNode>(mstInstance: T) {
  return {
    views: {
      get rootStore(): RootStore {
        return getRoot<RootStore>(mstInstance)
      },
    },
  }
}
