import { createTheme, MantineProvider } from '@mantine/core'
import { DatesProvider } from '@mantine/dates'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import type { FallbackRender } from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import { Route, Switch } from 'wouter'

import { AuthLayout } from '@/components/AuthLayout'
import { DashboardLayout } from '@/components/DashboardLayout'
import { PWAUpdateDialog } from '@/components/PWAUpdateDialog'
import { dateProviderSettings, modalDefaults } from '@/configs/componentDefaults'
import { mantineTheme } from '@/configs/theme'
import { useInitialGlobalState } from '@/hooks/useGlobalState'
import { NotFoundScreen } from '@/screens/404Screen'
import { createRouteStack } from '@/utils/navigation'

import { ErrorBoundaryScreen } from './screens/ErrorBoundaryScreen'

const routeStack = createRouteStack({ debug: false })
const theme = createTheme(mantineTheme)

const App = observer(function App() {
  const { globalState, isGlobalStateRehydrated } = useInitialGlobalState()

  if (!isGlobalStateRehydrated) return null

  const { isAppReady, authenticationStore } = globalState
  const { isAuthenticated } = authenticationStore

  const Layout = isAuthenticated ? DashboardLayout : AuthLayout

  if (!isAppReady) return null

  return (
    <QueryClientProvider client={globalState.api.queryClient}>
      <MantineProvider theme={theme}>
        <DatesProvider settings={dateProviderSettings}>
          <ModalsProvider modalProps={{ ...modalDefaults.defaultProps }}>
            <Layout>
              <Switch>
                {routeStack}
                <Route component={NotFoundScreen} />
              </Switch>
            </Layout>

            <PWAUpdateDialog />
          </ModalsProvider>

          <Notifications />
        </DatesProvider>
      </MantineProvider>
    </QueryClientProvider>
  )
})

export function FallBackApp(props: Parameters<FallbackRender>[0]) {
  return (
    <MantineProvider theme={theme}>
      <ModalsProvider modalProps={modalDefaults.defaultProps}>
        <ErrorBoundaryScreen {...props} />
        <PWAUpdateDialog />
      </ModalsProvider>
    </MantineProvider>
  )
}

export default App
