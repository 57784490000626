import type { ReactNode } from 'react'

import type { GetRoutePath, RouteNames } from '@/utils/navigation'
import { getRouteByName, getRoutePath } from '@/utils/navigation'

export type CommonAction = {
  label: ReactNode
  onClick?: () => void
  /**
   * Link url/path.
   * This will also be used to determine the `current` state of the link if UI supports it.
   */
  to?: GetRoutePath | string
  /**
   * Additional paths to consider as current.
   */
  additionalCurrentRoutes?: RouteNames[]
}

export type CommonActions = CommonAction[]

export function getCommonActionPath(to?: CommonAction['to']) {
  if (!to) return
  if (typeof to === 'string') return to
  return getRoutePath(to.name, to.params as any)
}

export function getCommonActionRoute(to?: CommonAction['to']) {
  if (!to) return
  if (typeof to === 'string') return
  return getRouteByName(to.name)
}
