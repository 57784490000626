import { Box, Text } from '@mantine/core'
import { IconMessages } from '@tabler/icons-react'
import type { MRT_ColumnDef } from 'mantine-react-table'
import { createMRTColumnHelper } from 'mantine-react-table'

import { CollapsibleContentSection } from '@/components/CollapsibleContentSection'
import { Table } from '@/components/Table'
import type { GetFAQsResponse } from '@/services/api/api.types'

import { useContentModuleScreenData } from './ContentModuleScreen'

export function ModuleFAQSection() {
  const data = useContentModuleScreenData()

  return (
    <CollapsibleContentSection
      defaultCollapsed
      id="module-faqs"
      title="Module FAQs"
      subtitle="Frequently asked questions about this module."
      ContentElement={<FAQSectionTable data={data.cmQuery.data?.faqs ?? []} />}
    />
  )
}

export function FAQSectionTable(props: { data: GetFAQsResponse | null }) {
  return (
    <Table
      enableTableHead={false}
      enableTopToolbar={false}
      data={props.data ?? []}
      columns={faqCols}
      mantineTableProps={{ highlightOnHover: false }}
      EmptyStateProps={{ title: 'No FAQs Added', Icon: IconMessages }}
    />
  )
}

const columnHelper = createMRTColumnHelper<GetFAQsResponse[0]>()
const faqCols: MRT_ColumnDef<GetFAQsResponse[0]>[] = [
  columnHelper.display({
    id: 'faq',
    header: 'FAQ',
    grow: true,
    Cell: props => {
      return (
        <Box>
          <Text size="sm" fw={500}>
            {props.row.original.question}
          </Text>
          <Text size="sm" c="dimmed">
            {props.row.original.answer}
          </Text>
        </Box>
      )
    },
  }),
]
