import { marked } from 'marked'

export function isMarkdown(value: string): boolean {
  const tokenTypes: string[] = []

  marked(value, {
    walkTokens: token => {
      tokenTypes.push(token.type)
    },
  })

  const isMarkdown = ['code', 'heading', 'link', 'table', 'url'].some(tokenType => tokenTypes.includes(tokenType))

  return isMarkdown
}
