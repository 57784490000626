import type { BoxProps } from '@mantine/core'
import { ActionIcon, Alert, Box, Loader, ScrollArea, Stack, Text, Textarea, useMantineTheme } from '@mantine/core'
import { getHotkeyHandler } from '@mantine/hooks'
import { Alignment, Fit, Layout, useRive } from '@rive-app/react-canvas'
import { IconBarrierBlock, IconSend2 } from '@tabler/icons-react'
import type { ReactNode } from 'react'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'

import avatars from '@/assets/avatars'
import { theme } from '@/configs/theme'
import { useGlobalState } from '@/hooks/useGlobalState'
import type { ChatStoreVolatileProps } from '@/services/state/ChatStore'
import * as classes from '@/styles/Chat.css'

export type ChatDataItem = {
  type: 'me' | 'smesk' | 'typing'
  message?: ReactNode
  options?: ReactNode[]
}

export function Chat() {
  const chatListViewport = useRef<HTMLDivElement>(null)

  const queueTimer = useRef<NodeJS.Timeout>(undefined)
  const [chatQueue, setChatQueue] = useState<ChatDataItem[]>([])
  const [chatData, setChatData] = useState<ChatDataItem[]>([])
  const [userChat, setUserChat] = useState('')

  const { chatStore } = useGlobalState()

  const { RiveComponent } = useRive({
    src: avatars.smesk,
    autoplay: true,
    animations: ['CLOSE', 'Idle'],
    layout: new Layout({ fit: Fit.Contain, alignment: Alignment.BottomCenter }),
  })

  function scrollChatListToBottom() {
    if (chatListViewport.current) {
      chatListViewport.current.scrollTo({ top: chatListViewport.current.scrollHeight, behavior: 'smooth' })
    }
  }

  const submitUserChat: ChatStoreVolatileProps['submitUserChatFn'] = (
    userMessage,
    smeskMessage,
    smeskMessageOptions,
  ) => {
    if (!userMessage) return

    setChatData(prevState => [...prevState.filter(d => d.type !== 'typing'), { type: 'me', message: userMessage }])
    setUserChat('')
    submitMockSMESKChat?.(smeskMessage, smeskMessageOptions)
  }

  const submitMockSMESKChat: ChatStoreVolatileProps['submitMockSMESKChatFn'] = (
    smeskMessage,
    smeskMessageOptions,
    opts,
  ) => {
    const { skipQueue = false, typing = false } = opts ?? {}

    const setFn = skipQueue ? setChatData : setChatQueue

    if (smeskMessage) {
      setFn(prevState => [
        ...prevState.filter(d => d.type !== 'typing'),
        { type: 'smesk', message: smeskMessage, options: smeskMessageOptions },
      ])
    }

    if (typing) {
      setChatData(prevState => [...prevState.filter(d => d.type !== 'typing'), { type: 'typing' }])
    }
  }

  useEffect(() => {
    scrollChatListToBottom()
  }, [chatData])

  useEffect(() => {
    chatStore.setSubmitUserChatFn(submitUserChat)
    chatStore.setSubmitMockSMESKChatFn(submitMockSMESKChat)
  }, [])

  useEffect(() => {
    if (queueTimer.current) {
      clearTimeout(queueTimer.current)
    }

    if (chatQueue.length) {
      queueTimer.current = setTimeout(() => {
        const [nextChat] = chatQueue
        submitMockSMESKChat(nextChat.message, nextChat.options, { skipQueue: true })
        setChatQueue(prevState => prevState.slice(1))
      }, 1000)
    }
  }, [chatQueue])

  return (
    <Stack className={classes.chatContainer} gap="lg">
      <Box className={classes.avatarContainer}>
        <RiveComponent className={classes.avatar} />
        <Text className={classes.avatarName} size="sm">
          Sidekick
        </Text>
      </Box>

      <ScrollArea viewportRef={chatListViewport} className={classes.chatList} scrollbarSize={10} scrollHideDelay={1500}>
        <Stack gap="sm">
          {chatData.map((item, index) => (
            <Bubble
              key={index}
              background={item.type === 'smesk' || item.type === 'typing' ? 'blue.1' : 'orange.0'}
              message={item.message}
              options={item.options}
              align={item.type === 'smesk' || item.type === 'typing' ? 'left' : 'right'}
              typing={item.type === 'typing'}
            />
          ))}
        </Stack>
      </ScrollArea>

      <Alert
        variant="light"
        color="orange"
        radius="sm"
        title="Full Functionality Coming Soon"
        icon={<IconBarrierBlock />}
        classNames={classes.alert}
      />

      <Textarea
        autosize
        size="sm"
        minRows={5}
        placeholder="Type a message..."
        value={userChat}
        onChange={event => setUserChat(event.currentTarget.value)}
        onKeyDown={getHotkeyHandler([['Enter', () => void submitUserChat(userChat)]])}
        rightSectionProps={{ className: classes.chatTextareaRightSection }}
        rightSection={
          <ActionIcon variant="gradient" size="lg" onClick={() => submitUserChat(userChat)}>
            <IconSend2 />
          </ActionIcon>
        }
      />
    </Stack>
  )
}

type BubbleProps = {
  background?: BoxProps['bg']
  align?: 'left' | 'right'
  message?: ChatDataItem['message']
  options?: ChatDataItem['options']
  typing?: boolean
}

function Bubble(props: BubbleProps) {
  const { background = 'gray.2', message, align = 'left', options = [], typing } = props

  const mantineTheme = useMantineTheme()

  const borderColor = useMemo(
    () => theme.darken(theme.getThemeColor(background as string, mantineTheme), 0.1),
    [background],
  )

  return (
    <Stack
      gap="sm"
      className={theme.cx(classes.bubbleContainer, typing && classes.bubbleContainerInline)}
      bg={background}
      align="flex-start"
      style={{
        alignSelf: align === 'left' ? 'flex-start' : 'flex-end',
        borderColor,
      }}>
      {!!typing && <Loader color="blue" type="dots" />}

      {!!message && <Text size="sm">{message}</Text>}

      {options.map((option, index) => (
        <Fragment key={index}>{option}</Fragment>
      ))}
    </Stack>
  )
}
