import type { SnapshotOrInstance } from 'mobx-state-tree'
import { type IStateTreeNode } from 'mobx-state-tree'

export function withSetProp<T extends IStateTreeNode>(mstInstance: T) {
  return {
    actions: {
      setProp<K extends keyof SnapshotOrInstance<T>, V extends SnapshotOrInstance<T>[K]>(field: K, newValue: V): void {
        // @ts-expect-error - for some reason TS complains about this, but it still works fine
        mstInstance[field] = newValue
      },
    },
  }
}
