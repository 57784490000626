import { alpha, darken, DEFAULT_THEME, em, getThemeColor, lighten, mergeMantineTheme, rem } from '@mantine/core'
import type { MantineVars } from '@mantine/vanilla-extract'
import { themeToVars } from '@mantine/vanilla-extract'
import { keyframes, style } from '@vanilla-extract/css'
import cx from 'clsx'

import { colors } from '@/configs/colors'
import { componentDefaults } from '@/configs/componentDefaults'

export const mantineTheme = mergeMantineTheme(DEFAULT_THEME, {
  fontFamily: 'Noto Sans',
  fontFamilyMonospace: 'Noto Sans Mono',
  headings: {
    fontFamily: 'Noto Sans',
    sizes: {
      // based one Display and Headline defs: https://m3.material.io/styles/typography/type-scale-tokens
      h1: { fontWeight: '400', fontSize: rem(57), lineHeight: rem(64) },
      h2: { fontWeight: '400', fontSize: rem(45), lineHeight: rem(52) },
      h3: { fontWeight: '400', fontSize: rem(36), lineHeight: rem(44) },
      h4: { fontWeight: '400', fontSize: rem(32), lineHeight: rem(40) },
      h5: { fontWeight: '400', fontSize: rem(28), lineHeight: rem(36) },
      h6: { fontWeight: '400', fontSize: rem(24), lineHeight: rem(32) },
    },
  },
  colors: colors,
  primaryColor: 'indigo',
  primaryShade: 6,
  defaultGradient: { from: 'indigo.7', to: 'cyan.4', deg: 120 },
  components: componentDefaults,
  spacing: { ...DEFAULT_THEME.spacing, xxl: rem(48), xxxl: rem(64) },
})

const themeHelpers = { em, rem, style, keyframes, alpha, cx, darken, lighten, getThemeColor }

export const theme: MantineVars & typeof themeHelpers = {
  ...themeToVars(mantineTheme),
  ...themeHelpers,
}
