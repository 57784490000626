import type { Instance, SnapshotOut } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'

import type { ChatDataItem } from '@/components/Chat'

export const ChatStoreModel = types
  .model('ChatStore')
  .props({})
  .volatile<ChatStoreVolatileProps>(_self => ({
    submitUserChatFn: undefined,
    submitMockSMESKChatFn: undefined,
  }))
  .actions(self => ({
    reset() {
      self.submitUserChatFn = undefined
      self.submitMockSMESKChatFn = undefined
    },
  }))
  .actions(self => ({
    setSubmitUserChatFn(fn: ChatStoreVolatileProps['submitUserChatFn']) {
      self.submitUserChatFn = fn
    },
    setSubmitMockSMESKChatFn(fn: ChatStoreVolatileProps['submitMockSMESKChatFn']) {
      self.submitMockSMESKChatFn = fn
    },
  }))

export interface ChatStoreVolatileProps {
  submitUserChatFn?: (
    userMessage: ChatDataItem['message'],
    smeskMessage?: ChatDataItem['message'],
    smeskMessageOptions?: ChatDataItem['options'],
  ) => void
  submitMockSMESKChatFn?: (
    smeskMessage?: ChatDataItem['message'],
    smeskMessageOptions?: ChatDataItem['options'],
    opts?: { skipQueue?: boolean; typing?: boolean },
  ) => void
}
export interface ChatStore extends Instance<typeof ChatStoreModel> {}
export interface ChatStoreSnapshot extends SnapshotOut<typeof ChatStoreModel> {}
