import type { RadioCardProps as MRadioCardProps } from '@mantine/core'
import { Box, Group, Radio, Text } from '@mantine/core'
import type { ReactNode } from 'react'

import { theme } from '@/configs/theme'
import * as classes from '@/styles/RadioCard.css'

interface RadioCardProps extends MRadioCardProps {
  label: ReactNode
  description?: ReactNode
}

export function RadioCard(props: RadioCardProps) {
  const { className, label, description, ...RadioCardProps } = props

  return (
    <Radio.Card radius="md" {...RadioCardProps} className={theme.cx(classes.card, className)}>
      <Group wrap="nowrap" align="flex-start">
        <Radio.Indicator />

        <Box>
          <Text className={classes.label}>{label}</Text>
          {!!description && <Text className={classes.description}>{description}</Text>}
        </Box>
      </Group>
    </Radio.Card>
  )
}
