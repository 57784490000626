import { Box, Collapse, Text, ThemeIcon, UnstyledButton } from '@mantine/core'
import type { IconProps } from '@tabler/icons-react'
import { IconChevronRight } from '@tabler/icons-react'
import { observer } from 'mobx-react-lite'
import type { ComponentType } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { Link, matchRoute, useLocation, useRouter } from 'wouter'

import { theme } from '@/configs/theme'
import * as classes from '@/styles/NavbarLinksGroup.css'
import type { CommonAction } from '@/utils/componentCommons'
import { getCommonActionPath, getCommonActionRoute } from '@/utils/componentCommons'
import { getRouteByName } from '@/utils/navigation'

export type LinksGroup = Omit<CommonAction, 'onClick'> & {
  icon?: ComponentType<IconProps>
  opened?: boolean
  links?: LinksGroup[]
}

type NavbarLinksGroupProps = LinksGroup
type NavbarLinkProps = Omit<LinksGroup, 'links' | 'open'> & {
  chevron?: boolean
  nested?: boolean
  onClick?: () => void
}

export function NavbarLinksGroup(props: NavbarLinksGroupProps) {
  const { to, label, links, icon: Icon, additionalCurrentRoutes = [] } = props

  const [opened, setOpened] = useState(props.opened ?? false)

  const hasNestedLinks = !!links?.length

  function toggle() {
    if (to) return
    if (!hasNestedLinks) return
    setOpened(o => !o)
  }

  return (
    <>
      <NavbarLink
        label={label}
        icon={Icon}
        opened={opened}
        chevron={hasNestedLinks}
        onClick={toggle}
        to={to}
        additionalCurrentRoutes={additionalCurrentRoutes}
      />

      {hasNestedLinks && (
        <Collapse in={opened} className={theme.cx(classes.collapse)}>
          {links.map((l, index) => (
            <NavbarLink
              nested
              key={typeof l.label === 'string' ? l.label : index}
              label={l.label}
              icon={l.icon}
              to={l.to}
              additionalCurrentRoutes={l.additionalCurrentRoutes}
            />
          ))}
        </Collapse>
      )}
    </>
  )
}

const NavbarLink = observer(function NavbarLink(props: NavbarLinkProps) {
  const {
    label,
    icon: Icon,
    chevron = false,
    opened = false,
    nested = false,
    onClick,
    to,
    additionalCurrentRoutes = [],
  } = props
  const routerParser = useRouter().parser
  const currentLocation = useLocation()[0]

  const path = useMemo(() => getCommonActionPath(to), [])
  const route = useMemo(() => getCommonActionRoute(to), [])

  const useRenderCondition = useCallback(() => {
    if (route?.renderConditionFn === undefined) {
      return true
    } else {
      return route.renderConditionFn()
    }
  }, [])

  const active = useMemo(() => {
    if (!nested && opened) return false

    const toPattern = typeof to === 'object' && 'name' in to ? getRouteByName(to.name)?.path : '/404'
    const additionalPatterns = additionalCurrentRoutes.map(route => getRouteByName(route)?.path)
    const patterns = [toPattern, ...additionalPatterns].filter(Boolean) as string[]

    return patterns.some(pattern => matchRoute(routerParser, pattern, currentLocation)[0])
  }, [currentLocation, nested, opened])

  const shouldRender = useRenderCondition()

  if (shouldRender !== true) return null

  return (
    <UnstyledButton
      className={theme.cx([classes.linkContainer, active && classes.linkContainerActive])}
      component={path ? Link : undefined}
      to={path}
      onClick={onClick}>
      <Box className={theme.cx([classes.linkWrapper, nested && classes.linkWrapperNested])}>
        {!!Icon && (
          <ThemeIcon variant={active ? 'filled' : 'light'} size={nested ? 32 : 40}>
            <Icon className={theme.cx([classes.linkIcon, nested && classes.linkIconNested])} />
          </ThemeIcon>
        )}

        <Text ml="md" size="sm" c={nested ? 'dimmed' : undefined} fw={500} className={classes.linkLabel}>
          {label}
        </Text>
      </Box>

      {chevron && (
        <IconChevronRight className={theme.cx([classes.caret, opened && classes.caretRotated])} stroke={1.5} />
      )}
    </UnstyledButton>
  )
})
