import { Box, Button, Code, Collapse, Container, Group, Stack } from '@mantine/core'
import { useDisclosure, useViewportSize } from '@mantine/hooks'
import type * as Sentry from '@sentry/react'
import { IconBug } from '@tabler/icons-react'
import { useMemo } from 'react'
import { serializeError } from 'serialize-error'

import { EmptyState } from '@/components/EmptyState'
import * as classes from '@/styles/ErrorBoundaryScreen.css'
import { clear } from '@/utils/storage'

export function ErrorBoundaryScreen(props: Parameters<Sentry.FallbackRender>[0]) {
  const { width } = useViewportSize()

  const [stackOpen, { toggle: toggleStack }] = useDisclosure()

  function refresh() {
    window.location.reload()
  }

  async function reset() {
    if ('serviceWorker' in navigator) {
      const regs = await navigator.serviceWorker.getRegistrations()

      for (const registration of regs) {
        await registration.unregister()
      }

      const cacheNames = await caches.keys()

      for (const key of cacheNames) {
        await caches.delete(key)
      }
    }

    clear()

    refresh()
  }

  const { message, stack } = useMemo(() => {
    if (props.error instanceof Error) {
      const err = serializeError(props.error)
      return { message: err.message, stack: err.stack }
    } else {
      return {}
    }
  }, [props.error])

  return (
    <Stack className={classes.root} justify="center">
      <Box className={classes.inner}>
        <IconBug size={width * 0.6} className={classes.image} />

        <Stack gap="xl" pos="relative">
          <EmptyState
            size="lg"
            pb="md"
            className={classes.content}
            title="Something went wrong!"
            TitleProps={{ c: 'red.9' }}
            DescriptionProps={{ c: 'gray.7' }}
            description="An error occurred while trying to render this page. Please try refreshing the page or resetting the application. If the issue persists, please contact support."
          />

          <Group justify="center" gap="xxl">
            <Button color="red.9" onClick={refresh}>
              Refresh Page
            </Button>

            <Button color="red.9" onClick={reset}>
              Reset Application
            </Button>
          </Group>

          {(!!stack || !!message) && (
            <Container size="xl">
              <Stack align="center">
                <Button color="red.9" variant="subtle" onClick={toggleStack} leftSection={<IconBug size={22} />}>
                  {stackOpen ? 'Hide' : 'Show'} Error Details
                </Button>

                <Collapse in={stackOpen}>
                  <Code block color="red.1" c="red.9" p="xl">
                    {stack || message}
                  </Code>
                </Collapse>
              </Stack>
            </Container>
          )}
        </Stack>
      </Box>
    </Stack>
  )
}
