import { Button, Text } from '@mantine/core'
import { IconBook, IconExternalLink } from '@tabler/icons-react'
import type { MRT_ColumnDef } from 'mantine-react-table'
import { observer } from 'mobx-react-lite'

import { CollapsibleContentSection } from '@/components/CollapsibleContentSection'
import { Table } from '@/components/Table'
import { useGlobalState } from '@/hooks/useGlobalState'
import type { GetResourcesResponse } from '@/services/api/api.types'

import { useContentModuleScreenData } from './ContentModuleScreen'

export function ModuleResourcesSection() {
  const data = useContentModuleScreenData()

  return (
    <CollapsibleContentSection
      defaultCollapsed
      id="module-resources"
      title="Module Supplemental Resources"
      subtitle="Supplemental resources for this module."
      ContentElement={<ModuleResourcesTable data={data.cmResourcesQuery.data ?? []} />}
    />
  )
}

export const ModuleResourcesTable = observer(function ModuleResourcesTable(props: {
  data: GetResourcesResponse | null
}) {
  return (
    <Table
      enableTableHead={!!props.data?.length}
      enableTopToolbar={false}
      data={props.data ?? []}
      columns={resourceCols}
      mantineTableProps={{ highlightOnHover: false }}
      EmptyStateProps={{ title: 'No Supplemental Resources Added', Icon: IconBook }}
    />
  )
})

const resourceCols: MRT_ColumnDef<GetResourcesResponse['0']>[] = [
  {
    accessorKey: 'title',
    header: 'Title',
    Cell: props => (
      <Text size="sm" fw={500}>
        {props.renderedCellValue}
      </Text>
    ),
  },
  {
    id: 'type',
    accessorFn: row => row.type?.toUpperCase(),
    header: 'Type',
    size: 100,
    grow: false,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    grow: true,
  },
  {
    id: 'data',
    header: 'Resource',
    size: 178,
    grow: false,
    Cell: props => {
      const { configStore } = useGlobalState()
      const sectionProps = useContentModuleScreenData()
      const type = props.row.original.type
      const previewLinkBase = type === 'url' ? '' : `${configStore.serverBaseUrl}/files/`

      return (
        <Button
          tt="uppercase"
          size="xs"
          fullWidth
          onClick={() => {
            if (type !== 'text') return

            sectionProps.modal.current?.openModal({
              title: 'Previewing Resource Content',
              data: props.row.original.data,
              opts: { markdown: true },
            })
          }}
          rightSection={type !== 'text' ? <IconExternalLink size={16} /> : undefined}
          component={type !== 'text' ? 'a' : undefined}
          target={type !== 'text' ? '_blank' : undefined}
          href={type === 'text' ? undefined : `${previewLinkBase}${props.row.original.data}`}>
          {type === 'text' ? 'View Resource' : `Preview ${type}`}
        </Button>
      )
    },
  },
]
