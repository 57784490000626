import { Button, Text } from '@mantine/core'
import { IconCheck, IconList, IconRepeat } from '@tabler/icons-react'
import type { z } from 'zod'

import { theme } from '@/configs/theme'
import { useGlobalState } from '@/hooks/useGlobalState'
import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import type { FAQReviewModalProps } from '@/screens/ContentModuleFormScreen/FAQReviewModal'
import { FAQReviewModal } from '@/screens/ContentModuleFormScreen/FAQReviewModal'
import type { GenerateFAQsResponse, GenerateMetadataParams, GenerateMetadataResponse } from '@/services/api/api.types'

export function useMockSMESKChatIntegration<T extends z.ZodType>(
  props: CMFormSection,
  updateServerFn?: (field: keyof z.infer<T>) => void,
) {
  const { chatStore } = useGlobalState()

  type AskSMESKForFieldOpts = {
    type: 'cm' | 'step' | 'resource'
    actions?: ('accept' | 'regenerate')[]
    apiParamField: GenerateMetadataParams['field']
    apiResponseField: keyof GenerateMetadataResponse['generated'][0]
    formField: keyof z.infer<T>
    userChatFieldLabel?: string
    numberOfOptions?: number
    skipUserChat?: boolean
  }
  async function askSMESKForField(opts?: AskSMESKForFieldOpts) {
    const {
      actions = [],
      apiParamField,
      numberOfOptions = 1,
      type,
      apiResponseField,
      userChatFieldLabel,
      skipUserChat = false,
      formField,
    } = opts ?? {}

    if (!apiParamField) return
    if (!apiResponseField) return
    if (!formField) return

    if (!skipUserChat) {
      const scopeLabel = type === 'cm' ? "module's" : type === 'step' ? "step's" : "supplemental resource's"

      chatStore.submitUserChatFn?.(
        <>
          Can you help me with the {scopeLabel} &quot;
          <Text component="span" inherit fw={600} fs="italic">
            {userChatFieldLabel ?? apiParamField}
          </Text>
          &quot; field?
        </>,
      )
    }

    chatStore.submitMockSMESKChatFn?.(undefined, undefined, { skipQueue: true, typing: true })

    const idParams =
      type === 'cm'
        ? { task_id: props.taskId! }
        : type === 'step'
          ? { step_id: props.stepId! }
          : { resource_id: props.resourceId! }

    const response = await props.generateMetadataMutation.mutateAsync({
      ...idParams,
      number_of_options: numberOfOptions,
      field: apiParamField,
    })

    const firstGeneratedSuggestion = response.generated?.[0]?.[apiResponseField]

    if (firstGeneratedSuggestion) {
      chatStore.submitMockSMESKChatFn?.(
        <>
          How about:
          <br />
          <Text component="span" fw={600} fs="italic" inherit mt={theme.rem(8)} mb={theme.rem(8)}>
            &quot;{firstGeneratedSuggestion}&quot;
          </Text>
        </>,
        actions.map(a => {
          switch (a) {
            case 'accept':
              return (
                <Button
                  key="accept"
                  size="xs"
                  color="green.7"
                  leftSection={<IconCheck stroke={1.5} size={20} />}
                  onClick={() => {
                    const form =
                      type === 'cm' ? props.moduleForm : type === 'step' ? props.stepForm : props.resourceForm

                    // @ts-expect-error -- form union type is not inferred correctly - this will be removed eventually
                    form.setFieldValue(formField, firstGeneratedSuggestion)
                    updateServerFn?.(formField)
                  }}>
                  Accept
                </Button>
              )
            case 'regenerate':
              return (
                <Button
                  key="regenerate"
                  size="xs"
                  color="blue"
                  leftSection={<IconRepeat stroke={1.5} size={20} />}
                  onClick={() => askSMESKForField({ ...opts, skipUserChat: true } as AskSMESKForFieldOpts)}>
                  Regenerate
                </Button>
              )
            default:
              return null
          }
        }),
        { skipQueue: true },
      )
    } else {
      chatStore.submitMockSMESKChatFn?.("I'm sorry, but I can't provide you with a suggestion", [], { skipQueue: true })
    }
  }

  type AskSMESKForFAQsOpts = {
    type: 'cm' | 'step'
  }
  async function askSMESKForFAQs(opts?: AskSMESKForFAQsOpts) {
    const { type } = opts ?? {}

    function openFAQReviewModal(data: GenerateFAQsResponse['questions']) {
      const title = type === 'cm' ? 'Review Suggested FAQs for module' : 'Review Suggested FAQs for section'
      const subtitle =
        type === 'cm'
          ? 'Select or edit list of FAQs for the module and then save them.'
          : 'Select or edit list of FAQs for the section and then save them.'

      props.openModal?.({
        title,
        subtitle,
        Component: FAQReviewModal,
        ComponentProps: { type: type!, faqs: data } satisfies FAQReviewModalProps,
      })
    }

    const scopeLabel = type === 'cm' ? 'module' : 'step'

    chatStore.submitUserChatFn?.(<>Can you help me come up with some FAQs for the {scopeLabel}?</>)

    chatStore.submitMockSMESKChatFn?.(undefined, undefined, { skipQueue: true, typing: true })

    const idParams = type === 'cm' ? { task_id: props.taskId! } : { step_id: props.stepId! }

    const response = await props.generateFAQsMutation.mutateAsync(idParams)

    if (response.questions?.length) {
      chatStore.submitMockSMESKChatFn?.(
        <>I came up with {response.questions.length} FAQs for you:</>,
        [
          <Button
            key="review"
            size="xs"
            color="blue"
            leftSection={<IconList stroke={1.5} size={20} />}
            onClick={() => openFAQReviewModal(response.questions)}>
            Review FAQs
          </Button>,
        ],
        { skipQueue: true },
      )
    } else {
      chatStore.submitMockSMESKChatFn?.("I'm sorry, but I can't come up with any FAQs.", [], { skipQueue: true })
    }
  }

  return { askSMESKForField, askSMESKForFAQs }
}
