import type { MantineThemeComponents } from '@mantine/core'
import {
  Button,
  Checkbox,
  LoadingOverlay,
  Modal,
  MultiSelect,
  Overlay,
  PasswordInput,
  Radio,
  Select,
  TagsInput,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core'
import type { DatesProviderSettings } from '@mantine/dates'
import { DatePickerInput } from '@mantine/dates'
import { Notifications } from '@mantine/notifications'
import cx from 'clsx'

import { colors } from '@/configs/colors'
import * as classes from '@/styles/componentDefaults.css'
import type { DeepPartial } from '@/types/helpers'

const inputLikeDefaults = {
  defaultProps: { radius: 'sm', size: 'lg', inputWrapperOrder: ['label', 'input', 'description', 'error'] },
  classNames: (_t, props) => {
    return {
      root: classes.textInputRoot,
      wrapper: classes.textInputWrapper,
      description: cx(classes.textInputDescription, props.error && classes.textInputDescriptionHidden),
      label: classes.textInputLabel,
      error: classes.textInputError,
      input: classes.textInput,
    }
  },
} satisfies Parameters<typeof TextInput.extend>[0]

const toggleLikeGroupDefaults = {
  defaultProps: { size: 'lg', inputWrapperOrder: ['label', 'input', 'description', 'error'] },
  classNames: (_t, props) => {
    return {
      root: classes.textInputRoot,
      wrapper: classes.textInputWrapper,
      description: cx(classes.textInputDescription, props.error && classes.textInputDescriptionHidden),
      label: classes.textInputLabel,
      error: classes.textInputError,
    }
  },
} satisfies Parameters<typeof Checkbox.Group.extend>[0]

export const modalDefaults: Parameters<typeof Modal.extend>[0] = {
  defaultProps: { zIndex: 220 },
  classNames: { body: classes.modalBody },
}

export const dateProviderSettings: DatesProviderSettings = {
  consistentWeeks: true,
}

export const componentDefaults: DeepPartial<MantineThemeComponents> = {
  Overlay: Overlay.extend({ defaultProps: { color: colors.indigo[2], blur: 2, backgroundOpacity: 0.5 } }),
  LoadingOverlay: LoadingOverlay.extend({
    defaultProps: {
      overlayProps: { color: colors.indigo[2], blur: 2, backgroundOpacity: 0.5 },
      loaderProps: { size: 'xl', type: 'dots' },
    },
  }),
  Tooltip: Tooltip.extend({ defaultProps: { zIndex: 240 } }),
  Modal: Modal.extend(modalDefaults),
  Button: Button.extend({ defaultProps: { radius: 'sm', size: 'md' } }),
  TextInput: TextInput.extend(inputLikeDefaults as Parameters<typeof TextInput.extend>[0]),
  PasswordInput: PasswordInput.extend(inputLikeDefaults as Parameters<typeof PasswordInput.extend>[0]),
  Select: Select.extend(inputLikeDefaults as Parameters<typeof Select.extend>[0]),
  MultiSelect: MultiSelect.extend(inputLikeDefaults as Parameters<typeof MultiSelect.extend>[0]),
  TagsInput: TagsInput.extend(inputLikeDefaults as Parameters<typeof TagsInput.extend>[0]),
  DatePickerInput: DatePickerInput.extend(inputLikeDefaults as Parameters<typeof DatePickerInput.extend>[0]),
  Textarea: Textarea.extend(inputLikeDefaults as Parameters<typeof Textarea.extend>[0]),
  CheckboxGroup: Checkbox.Group.extend(toggleLikeGroupDefaults),
  Checkbox: Checkbox.extend({ defaultProps: { radius: 'sm', size: 'md' } }),
  RadioGroup: Radio.Group.extend(toggleLikeGroupDefaults as Parameters<typeof Radio.Group.extend>[0]),
  Radio: Radio.extend({ defaultProps: { radius: 'xl', size: 'md' } }),
  RadioIndicator: Radio.Indicator.extend({ defaultProps: { radius: 'xl', size: 'md' } }),
  Notifications: Notifications.extend({ defaultProps: { zIndex: 250, autoClose: 10000 } }),
}
