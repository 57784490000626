import { Modal, TextInput } from '@mantine/core'
import { IconSearch, IconZoomCancel } from '@tabler/icons-react'
import { observer } from 'mobx-react-lite'

import { EmptyState } from '@/components/EmptyState'
import { useGlobalState } from '@/hooks/useGlobalState'
import { ModalStatus } from '@/services/state/UIStore'
import * as classes from '@/styles/SearchModal.css'

export const SearchModal = observer(function SearchModal() {
  const { uiStore } = useGlobalState()
  const { searchModalStatus, setProp } = uiStore

  function closeModal() {
    setProp('searchModalStatus', ModalStatus.Closed)
  }

  return (
    <Modal.Root size="lg" opened={searchModalStatus === ModalStatus.Open} onClose={closeModal} radius="lg">
      <Modal.Overlay />

      <Modal.Content className={classes.modalContent}>
        <Modal.Header className={classes.modalHeader}>
          <TextInput
            data-autofocus
            placeholder="Search Sidekick"
            size="xl"
            radius={0}
            variant="unstyled"
            classNames={{
              input: classes.searchInput,
              root: classes.searchRoot,
            }}
            leftSection={<IconSearch />}
          />
        </Modal.Header>

        <Modal.Body>
          <EmptyState
            Icon={IconZoomCancel}
            title="No Results Found"
            description="Please try a different search term."
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
})
