import {
  Avatar,
  Checkbox,
  Grid,
  Group,
  MultiSelect,
  Radio,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useDebouncedCallback } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import { IconCalendar } from '@tabler/icons-react'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import type { z } from 'zod'

import avatars from '@/assets/avatars'
import { AIActionButton } from '@/components/AIActionButton'
import { ComingSoon } from '@/components/ComingSoon'
import { InputLabel } from '@/components/InputLabel'
import { RadioCard } from '@/components/RadioCard'
import { theme } from '@/configs/theme'
import { useMockSMESKChatIntegration } from '@/hooks/useMockSMESKIntegration'
import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import type { GetESKsResponse } from '@/services/api/api.types'
import * as commonClasses from '@/styles/componentCommons.css'
import * as classes from '@/styles/ContentModuleFormScreen.css'
import type { ModuleFormSchema } from '@/utils/contentModuleForm'
import { castZodFormToContentModule, moduleSchema } from '@/utils/contentModuleForm'
import { getRequiredFieldDescriptionProps } from '@/utils/forms'
import { pickFromObject } from '@/utils/objects'

export const RequiredSettingsSection = observer(function RequiredSettingsSection(props: CMFormSection) {
  useEffect(() => {
    // YULIANTODO: hack to set defaults for are_steps_sequential
    if (props.cmQuery.data && typeof props.cmQuery.data.areStepSequential !== 'boolean') {
      updateModuleDebounced(['presentationMode'])
    }
  }, [props.cmQuery.data])

  const topic = props.moduleForm.getValues().categories[0]
  const topicOptions = useMemo(() => topicOptionsMapper(props.topicsQuery.data ?? []), [props.topicsQuery.data])
  const eskOptions = useMemo(() => eskOptionsMapper(props.esksQuery.data ?? []), [props.esksQuery.data])

  const subTopics = useMemo(() => {
    const t = props.topicsQuery.data?.find(t => t.displayName === topic)
    if (!t) return []

    return topicOptionsMapper(t.subtopics)
  }, [topic])

  const eskForTopic = useMemo(() => {
    const t = props.topicsQuery.data?.find(t => t.displayName === topic)
    if (!t) return null

    const esk = props.esksQuery.data?.find(e => e.topicId === t.id)
    return esk?.eskId ?? null
  }, [props.esksQuery.data, props.topicsQuery.data, topic])

  const eskAvatar = useMemo(() => {
    if (!props.esksQuery.data) return undefined

    const esk = props.esksQuery.data.find(e => e.eskId === props.moduleForm.getValues().selectEsk)

    return esk ? avatars[esk.avatarId] : undefined
  }, [props.moduleForm.getValues().selectEsk, props.esksQuery.data])

  const updateModuleDebounced = useDebouncedCallback((fields: Array<keyof z.infer<ModuleFormSchema>>) => {
    const values = props.moduleForm.getValues()

    const params = castZodFormToContentModule(pickFromObject(values, fields))

    props.cmUpdateMutation?.mutate({ ...params, task_id: props.taskId! })
  }, 1000)

  const { askSMESKForField } = useMockSMESKChatIntegration<ModuleFormSchema>(props, field =>
    updateModuleDebounced([field]),
  )

  return (
    <Grid gutter="xxxl">
      <Grid.Col span={6}>
        <TextInput
          withAsterisk
          label="Title"
          rightSection={
            <AIActionButton
              variant="action-button"
              onClick={() =>
                askSMESKForField({
                  type: 'cm',
                  userChatFieldLabel: 'Title',
                  formField: 'name',
                  apiParamField: 'name',
                  apiResponseField: 'name',
                  actions: ['accept', 'regenerate'],
                })
              }
            />
          }
          {...props.moduleForm.getInputProps('name')}
          onChange={e => {
            props.moduleForm.getInputProps('name').onChange(e)
            updateModuleDebounced(['name'])
          }}
          {...getRequiredFieldDescriptionProps(props.moduleForm, 'name', 'Name of your module.')}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <Select
          withAsterisk
          clearable
          label="Topic"
          data={topicOptions}
          {...props.moduleForm.getInputProps('categories')}
          value={topic ?? null}
          onClear={() => updateModuleDebounced(['categories', 'selectEsk', 'mappings'])}
          // TODO: eventually this will support selecting multiple categories
          {...getRequiredFieldDescriptionProps(
            props.moduleForm,
            'categories',
            'Select the topic your module is about.',
          )}
          onChange={v => {
            props.moduleForm.setFieldValue('mappings', [])

            const topic = props.topicsQuery.data?.find(t => t.displayName === v)

            if (!v || !topic) {
              props.moduleForm.setFieldValue('categories', [])
              props.moduleForm.setFieldValue('selectEsk', '')
            } else {
              props.moduleForm.setFieldValue('categories', [topic.displayName])

              const esk = props.esksQuery.data?.find(e => e.topicId === topic.id)

              if (esk) {
                props.moduleForm.setFieldValue('selectEsk', esk.eskId)
              }
            }

            updateModuleDebounced(['categories', 'selectEsk', 'mappings'])
          }}
        />
      </Grid.Col>

      {!!props.moduleForm.getValues().categories.length && (
        <>
          <Grid.Col span={6}>
            <Group gap="lg">
              <Avatar
                variant={!!eskAvatar ? 'transparent' : 'light'}
                className={theme.cx(classes.eskAvatarBase, !!eskAvatar && classes.eskAvatar)}
                radius="sm"
                size="lg"
                src={eskAvatar}
              />

              <Select
                flex={1}
                withAsterisk
                label="Expert Sidekick"
                data={eskOptions}
                {...props.moduleForm.getInputProps('selectEsk')}
                {...getRequiredFieldDescriptionProps(
                  props.moduleForm,
                  'selectEsk',
                  'Select an Expert Sidekick to present this module.',
                )}
                onClear={() => updateModuleDebounced(['selectEsk'])}
                onChange={e => {
                  if (eskForTopic && e !== eskForTopic) {
                    modals.openConfirmModal({
                      title: 'Are you sure?',
                      centered: true,
                      children: (
                        <Text size="sm">{`The area of expertise for the Expert Sidekick you have chosen is not "${props.moduleForm.getValues().categories?.[0]}". Would you like to proceed?`}</Text>
                      ),
                      labels: { confirm: 'Yes', cancel: 'Cancel' },
                      groupProps: { mt: 'xl' },
                      onConfirm: () => {
                        props.moduleForm.setFieldValue('selectEsk', e as z.infer<ModuleFormSchema>['selectEsk'])
                        updateModuleDebounced(['selectEsk'])
                      },
                    })
                  } else {
                    props.moduleForm.setFieldValue('selectEsk', e as z.infer<ModuleFormSchema>['selectEsk'])
                    updateModuleDebounced(['selectEsk'])
                  }
                }}
              />
            </Group>
          </Grid.Col>

          <Grid.Col span={6}>
            {!!subTopics.length && (
              <MultiSelect
                withAsterisk
                label="Sub-Topics"
                data={subTopics}
                {...props.moduleForm.getInputProps('mappings')}
                {...getRequiredFieldDescriptionProps(
                  props.moduleForm,
                  'mappings',
                  'Select sub-topics within selected topic.',
                )}
                onChange={e => {
                  props.moduleForm.getInputProps('mappings').onChange(e)
                  updateModuleDebounced(['mappings'])
                }}
                onRemove={() => updateModuleDebounced(['mappings'])}
              />
            )}
          </Grid.Col>
        </>
      )}

      <Grid.Col span={6}>
        <Textarea
          withAsterisk
          label="User-Facing Description"
          autosize
          resize="vertical"
          classNames={{ input: classes.mskIntroductionInput }}
          rightSectionProps={{ className: commonClasses.textareaRightSectionWithAIActionButton }}
          rightSection={
            <AIActionButton
              variant="action-button"
              onClick={() =>
                askSMESKForField({
                  type: 'cm',
                  formField: 'shortDescription',
                  userChatFieldLabel: 'User-Facing Description',
                  apiParamField: 'short_description',
                  apiResponseField: 'shortDescription',
                  actions: ['accept', 'regenerate'],
                })
              }
            />
          }
          {...props.moduleForm.getInputProps('shortDescription')}
          {...getRequiredFieldDescriptionProps(
            props.moduleForm,
            'shortDescription',
            'Sidekicks will use this to describe the module to users.',
          )}
          onChange={e => {
            props.moduleForm.getInputProps('shortDescription').onChange(e)
            updateModuleDebounced(['shortDescription'])
          }}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <Stack gap="xxxl">
          <DatePickerInput
            clearable
            label={<ComingSoon prefix="Expiration Date " />}
            leftSection={<IconCalendar size={24} stroke={1.5} />}
            leftSectionPointerEvents="none"
            {...props.moduleForm.getInputProps('expirationDate')}
            {...getRequiredFieldDescriptionProps(
              props.moduleForm,
              'expirationDate',
              'Module will be auto-archived on this date.',
            )}
          />

          <DatePickerInput
            clearable
            label={<ComingSoon prefix="Due Date " />}
            leftSection={<IconCalendar size={24} stroke={1.5} />}
            leftSectionPointerEvents="none"
            {...props.moduleForm.getInputProps('dueDate')}
            {...getRequiredFieldDescriptionProps(
              props.moduleForm,
              'dueDate',
              'Deadline for users to complete the module.',
            )}
          />
        </Stack>
      </Grid.Col>

      <Grid.Col span={6}>
        <InputLabel label={<ComingSoon prefix="Who can access this module? " />} withDivider mb={theme.rem(12)} />

        <Stack gap="xl">
          <Checkbox.Group label="Age Groups" value={['disabled']}>
            <Grid gutter="sm">
              <Grid.Col span={3}>
                <Checkbox label="18-24" value="18-24" />
              </Grid.Col>

              <Grid.Col span={3}>
                <Checkbox label="25-35" value="25-35" />
              </Grid.Col>

              <Grid.Col span={3}>
                <Checkbox label="36-50" value="36-50" />
              </Grid.Col>

              <Grid.Col span={3}>
                <Checkbox label="51+" value="51+" />
              </Grid.Col>
            </Grid>
          </Checkbox.Group>

          <Checkbox.Group label="Language" value={['disabled']}>
            <Grid gutter="sm">
              <Grid.Col span={3}>
                <Checkbox label="English" value="English" />
              </Grid.Col>

              <Grid.Col span={3}>
                <Checkbox label="Spanish" value="Spanish" />
              </Grid.Col>
            </Grid>
          </Checkbox.Group>

          <Radio.Group label="Gender" value="disabled">
            <Grid gutter="sm">
              <Grid.Col span={3}>
                <Radio label="Men" value="Men" />
              </Grid.Col>

              <Grid.Col span={3}>
                <Radio label="Women" value="Women" />
              </Grid.Col>

              <Grid.Col span={3}>
                <Radio label="All" value="All" />
              </Grid.Col>
            </Grid>
          </Radio.Group>
        </Stack>
      </Grid.Col>

      <Grid.Col span={6}>
        <Textarea
          label={<ComingSoon prefix="Who is your content most useful for? " />}
          autosize
          resize="vertical"
          classNames={{ input: classes.mostUsefulForInput }}
        />
      </Grid.Col>

      <Grid.Col span={12}>
        <Radio.Group
          label="Module Flow"
          withAsterisk
          {...props.moduleForm.getInputProps('presentationMode')}
          onChange={e => {
            props.moduleForm.setFieldValue('presentationMode', e as z.infer<ModuleFormSchema>['presentationMode'])
            updateModuleDebounced(['presentationMode'])
          }}>
          <Group gap="xl" align="stretch">
            <RadioCard
              value={moduleSchema.shape.presentationMode.enum.member_directed}
              label="User Directed"
              description="Users can access the content in any order."
            />

            <RadioCard
              value={moduleSchema.shape.presentationMode.enum.sequential}
              label={<ComingSoon prefix="Sequential " />}
              disabled
              description="Their first time through, the user must go through the content in the order it's laid out."
            />
          </Group>
        </Radio.Group>
      </Grid.Col>
    </Grid>
  )
})

function eskOptionsMapper(data: GetESKsResponse) {
  return data.map(esk => ({ value: esk.eskId, label: esk.name ?? `Avatar ${esk.eskId}` }))
}

function topicOptionsMapper(data: { displayName: string }[]) {
  return data.map(c => ({ value: c.displayName, label: c.displayName })).filter(c => !!c.label)
}
