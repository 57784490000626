import { Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { IconRefresh } from '@tabler/icons-react'
import { useEffect } from 'react'
import { useRegisterSW } from 'virtual:pwa-register/react'

import { Consts } from '@/configs/consts'

export function PWAUpdateDialog() {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(_swUrl, r) {
      if (r) {
        setInterval(
          () => r.update(),
          Consts.isDev || window.location.hostname.includes('ngrok') ? 1000 * 60 : 1000 * 60 * 10,
        )
      }
    },
  })

  useEffect(() => {
    if (needRefresh) {
      modals.openConfirmModal({
        title: 'New Version Available',
        centered: true,
        children: (
          <Text size="sm">{`The SME Portal has been updated. Please refresh the page to see the latest changes.`}</Text>
        ),
        labels: { confirm: 'Refresh Portal', cancel: 'Cancel' },
        groupProps: { mt: 'xl' },
        onConfirm: () => updateServiceWorker(true),
        confirmProps: {
          leftSection: <IconRefresh />,
        },
      })
    }
  }, [needRefresh])

  return null
}
