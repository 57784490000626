import { ActionIcon, Alert, Button, Group, PasswordInput, Stack, Text, TextInput, Title } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { getHotkeyHandler } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { IconAlertOctagon, IconCircleCheckFilled, IconCircleDashedCheck } from '@tabler/icons-react'
import { observer } from 'mobx-react-lite'
import { parse } from 'qs'
import type { ComponentProps } from 'react'
import { useMemo, useState } from 'react'
import { useSearch } from 'wouter'
import { z } from 'zod'

import { useGlobalState } from '@/hooks/useGlobalState'
import type { ForgotResponseError } from '@/services/api/api.types'
import * as commonClasses from '@/styles/componentCommons.css'
import { navigateTo } from '@/utils/navigation'

const oneLowerRx = /(?=.*?[a-z])/
const oneUpperRx = /(?=.*?[A-Z])/
const oneNumberRx = /(?=.*?[0-9])/
const oneSpecialRx = /(?=.*?[#?!@$%^&*-])/
const min8Rx = /.{8,}/

const ChangePasswordSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  oldPassword: z.string().min(1, "Can't be empty"),
  newPassword: z.string().min(8).regex(oneUpperRx).regex(oneLowerRx).regex(oneNumberRx).regex(oneSpecialRx),
})

export const ChangePasswordScreen = observer(function ChangePasswordScreen() {
  const searchParamsString = useSearch()
  const searchParams: { email?: string } = parse(searchParamsString)

  const { uiStore, api, authenticationStore } = useGlobalState()
  const { logIn } = authenticationStore
  const [authError, setAuthError] = useState<string | null>(null)

  const form = useForm<z.infer<typeof ChangePasswordSchema>>({
    mode: 'controlled',
    validate: zodResolver(ChangePasswordSchema),
    initialValues: {
      email: searchParams.email ?? '',
      oldPassword: '',
      newPassword: '',
    },
  })

  const onKeyboardEnter = useMemo(() => getHotkeyHandler([['Enter', () => void submitForm()]]), [])

  async function submitForm() {
    setAuthError(null)

    const validations = form.validate()

    if (validations.hasErrors) {
      setAuthError('Double check your email and password.')
      return
    }

    uiStore.showLoadingOverlay('relative')

    const { email, oldPassword, newPassword } = form.getValues()

    const response = await api.safeRequest<typeof api.forgot, ForgotResponseError>(api.forgot, {
      username: email,
      old_password: oldPassword,
      new_password: newPassword,
    })

    if (!response.ok) {
      uiStore.hideLoadingOverlay()

      if (response.data.code === 'OLD_PASSWORD_INCORRECT') {
        setAuthError(
          response.data.message ?? 'The old password you entered is incorrect. Please double-check and try again.',
        )
        return
      }

      setAuthError(response.data.message ?? 'Something went wrong. ')
    } else {
      notifications.show({
        title: 'Password Changed!',
        message: 'Your password was changed successfully.',
        color: 'green',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })

      const loginResponse = await logIn({ email, password: newPassword })

      uiStore.hideLoadingOverlay()

      if (!loginResponse.ok) {
        navigateTo('login', { searchParams: { email } })
      }
    }
  }

  const PasswordRequirementsProps: Pick<ComponentProps<typeof PasswordRequirement>, 'value' | 'initial'> = {
    value: form.getValues().newPassword,
    initial: !form.errors.newPassword && !form.isDirty('newPassword'),
  }

  return (
    <Stack gap="xxl" maw={768} w="100%">
      <Title order={6} ta="center" fw={500}>
        Change your password:
      </Title>

      {authError && <Alert variant="light" color="red" title={authError} icon={<IconAlertOctagon />}></Alert>}

      <TextInput withAsterisk label="Email address" onKeyDown={onKeyboardEnter} {...form.getInputProps('email')} />
      <PasswordInput
        withAsterisk
        label="Old Password"
        onKeyDown={onKeyboardEnter}
        {...form.getInputProps('oldPassword')}
      />
      <PasswordInput
        withAsterisk
        label="New Password"
        onKeyDown={onKeyboardEnter}
        {...form.getInputProps('newPassword')}
        error={!!form.errors.newPassword}
      />

      <Stack mt={-32}>
        <PasswordRequirement {...PasswordRequirementsProps} rx={min8Rx} message="8 character minimum" />
        <PasswordRequirement {...PasswordRequirementsProps} rx={oneLowerRx} message="one lowercase character" />
        <PasswordRequirement {...PasswordRequirementsProps} rx={oneUpperRx} message="one uppercase character" />
        <PasswordRequirement {...PasswordRequirementsProps} rx={oneNumberRx} message="one number character" />
        <PasswordRequirement {...PasswordRequirementsProps} rx={oneSpecialRx} message="one special character" />
      </Stack>

      <Stack align="center">
        <Button size="lg" onClick={() => void submitForm()} fullWidth>
          Change Password
        </Button>

        <Button variant="subtle" color="gray" size="compact-lg" onClick={() => navigateTo('auth')}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  )
})

function PasswordRequirement(props: { value: string; rx: RegExp; message: string; initial: boolean }) {
  const isValid = props.rx.test(props.value)
  const color = props.initial ? 'gray' : isValid ? 'green.9' : 'red'

  return (
    <Group>
      <ActionIcon radius="xl" color={color} variant="light">
        {isValid ? <IconCircleCheckFilled /> : <IconCircleDashedCheck />}
      </ActionIcon>

      <Text c={color}>{props.message}</Text>
    </Group>
  )
}
