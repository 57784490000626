import type { StackProps } from '@mantine/core'
import { Group, Stack, Text, ThemeIcon, Title } from '@mantine/core'
import type { IconProps } from '@tabler/icons-react'
import type { ComponentType, PropsWithChildren, ReactNode } from 'react'

import { theme } from '@/configs/theme'

type ScreenProps = PropsWithChildren<{
  title: string
  icon: ComponentType<IconProps>
  description?: ReactNode
  BodyStackProps?: StackProps
}>

export function Screen(props: ScreenProps) {
  const { title, icon: Icon, description, BodyStackProps } = props

  return (
    <Stack maw={theme.rem(1330)} p={theme.spacing.xl} mx="auto" gap="xxl" mih="100dvh">
      <Group>
        <ThemeIcon variant="filled" size={theme.rem(42)}>
          <Icon />
        </ThemeIcon>
        <Title order={3}>{title}</Title>
      </Group>

      {!!description && (
        <Stack gap="xs">{typeof description === 'string' ? <Text>{description}</Text> : description}</Stack>
      )}

      <Stack flex={1} {...BodyStackProps}>
        {props.children}
      </Stack>
    </Stack>
  )
}
