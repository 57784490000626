import { Badge, Group } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'

type RadioContentCellProps = {
  items: { label: string; selected?: boolean }[]
}

export function RadioContentCell(props: RadioContentCellProps) {
  return (
    <Group>
      {props.items.map(item => (
        <Badge
          key={item.label}
          size="lg"
          variant={item.selected ? 'filled' : 'light'}
          color={item.selected ? undefined : 'gray'}
          leftSection={item.selected ? <IconCheck stroke={1.5} /> : undefined}>
          {item.label}
        </Badge>
      ))}
    </Group>
  )
}
