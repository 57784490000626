import { Box, Paper, Title } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import type { PropsWithChildren } from 'react'

import { GlobalLoadingOverlay } from '@/components/GlobalLoadingOverlay'
import * as classes from '@/styles/AuthLayout.css'

type AuthLayoutProps = PropsWithChildren

export const AuthLayout = observer(function AuthLayout(props: AuthLayoutProps) {
  return (
    <Box className={classes.wrapper}>
      <Paper className={classes.slot} radius={0}>
        {props.children}

        <GlobalLoadingOverlay />
      </Paper>

      <Box className={classes.splash}>
        <Title order={1} mb={16} className={classes.splashText}>
          Sidekick Wellness
        </Title>
        <Title order={5} className={classes.splashText}>
          Life is about to get easier
        </Title>
      </Box>
    </Box>
  )
})
