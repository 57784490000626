import { Box, Button, Grid, Group, Modal, Text } from '@mantine/core'
import { IconDeviceFloppy } from '@tabler/icons-react'
import type { MRT_ColumnDef, MRT_RowSelectionState } from 'mantine-react-table'
import { useState } from 'react'
import type { z } from 'zod'

import { Table } from '@/components/Table'
import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import type { FAQModalProps } from '@/screens/ContentModuleFormScreen/FAQModal'
import { FAQModal } from '@/screens/ContentModuleFormScreen/FAQModal'
import type { GenerateFAQsResponse } from '@/services/api/api.types'
import * as commonClasses from '@/styles/componentCommons.css'
import type { FAQFormSchema } from '@/utils/contentModuleForm'
import { castFAQToZodForm } from '@/utils/contentModuleForm'
import { getObjectKeys } from '@/utils/objects'

export type FAQReviewModalProps = {
  type: 'cm' | 'step'
  faqs: GenerateFAQsResponse['questions']
}

export function FAQReviewModal(props: CMFormSection & FAQReviewModalProps) {
  const [faqs, setFAQs] = useState<GenerateFAQsResponse['questions']>(props.faqs)

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})

  const selectedIndices = getObjectKeys(rowSelection)

  async function createFAQs() {
    const selectedFAQs = selectedIndices.map(index => faqs[Number(index)])

    const idParams = props.type === 'cm' ? { task_id: props.taskId! } : { step_id: props.stepId! }

    await props.faqsCreateMutation?.mutateAsync({ ...idParams, faqs: selectedFAQs })

    props.closeModal()
  }

  function openFAQModal() {
    props.openModal?.({
      title: `Edit Suggested FAQ`,
      subtitle: 'Edit the question and answer for this suggested FAQ.',
      Component: FAQModal,
      ComponentProps: { type: 'ai', onSubmit: upsertFAQ } satisfies FAQModalProps,
    })
  }

  function stageFAQ(faq?: GenerateFAQsResponse['questions'][0] & { faqId: string }) {
    props.faqForm.setValues(castFAQToZodForm(faq))
  }

  function upsertFAQ(faq: z.infer<FAQFormSchema>) {
    if (faq?.faqId === null) return

    setFAQs(prev => {
      prev[Number(faq?.faqId)] = faq
      return [...prev]
    })
  }

  const isMutating = props.faqsCreateMutation?.isPending

  return (
    <>
      <Box className={commonClasses.stickyModalFooterContentWrapper}>
        <Grid gutter="xxxl">
          <Grid.Col span={12}>
            <Table
              enableRowSelection
              enableTopToolbar={false}
              data={faqs ?? []}
              columns={faqColumnDefs}
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              rowActionsInclude={['edit']}
              onRowActionClick={(action, row) => {
                if (action === 'edit') {
                  stageFAQ({ ...row.original, faqId: String(row.index) })
                  openFAQModal()
                }
              }}
              displayColumnDefOptions={{ 'mrt-row-actions': { size: 60 } }}
            />
          </Grid.Col>
        </Grid>
      </Box>

      <Modal.Header className={commonClasses.stickyModalFooter}>
        <Group gap="xl">
          <Button
            leftSection={<IconDeviceFloppy />}
            onClick={createFAQs}
            loading={isMutating}
            disabled={!selectedIndices.length}>
            Save FAQs
          </Button>

          {!isMutating && (
            <Button size="sm" variant="light" color="gray" onClick={() => props.closeModal()}>
              Cancel
            </Button>
          )}
        </Group>
      </Modal.Header>
    </>
  )
}

const faqColumnDefs: MRT_ColumnDef<GenerateFAQsResponse['questions'][0]>[] = [
  {
    id: 'faq',
    header: 'FAQs',
    grow: true,
    Cell: props => {
      return (
        <Box>
          <Text size="sm" fw={500}>
            {props.row.original.question}
          </Text>
          <Text size="sm" c="dimmed">
            {props.row.original.answer}
          </Text>
        </Box>
      )
    },
  },
]
