import { FocusTrap, Loader, LoadingOverlay, Modal } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { useGlobalState } from '@/hooks/useGlobalState'

export const GlobalLoadingOverlay = observer(function GlobalLoadingOverlay() {
  const { uiStore } = useGlobalState()

  if (uiStore.globalLoadingOverlayStatus === 'relative') {
    return (
      <FocusTrap active>
        <LoadingOverlay visible data-autofocus />
      </FocusTrap>
    )
  } else if (uiStore.globalLoadingOverlayStatus === 'fullscreen') {
    return (
      <Modal.Root
        opened
        onClose={() => undefined}
        centered
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="auto">
        <Modal.Overlay />
        <FocusTrap.InitialFocus />
        <Modal.Content style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <Modal.Body>
            <Loader type="dots" size="xl" />
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    )
  } else {
    return null
  }
})
