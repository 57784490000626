import { Box, Collapse, Group, Paper, Stack, Text } from '@mantine/core'
import { useScrollIntoView } from '@mantine/hooks'
import { IconChevronRight } from '@tabler/icons-react'
import type { ComponentType, ReactNode, RefObject } from 'react'
import { useImperativeHandle, useMemo, useState } from 'react'

import { theme } from '@/configs/theme'
import * as classes from '@/styles/CollapsibleContentSection.css'

export type CollapsibleContentSectionRef =
  | {
      open: () => void
      close: () => void
      toggle: () => void
      scrollIntoView: ReturnType<typeof useScrollIntoView>['scrollIntoView']
    }
  | undefined

export type CollapsibleContentSectionProps = {
  id: string
  sectionRef?: RefObject<CollapsibleContentSectionRef>
  title?: ReactNode
  subtitle?: ReactNode
  ContentComponent?: ComponentType
  ContentElement?: ReactNode
  /**
   * Whether the section can be collapsed
   * @default true
   */
  collapsible?: boolean
  /**
   * Whether the section is initially opened
   * @default false
   */
  defaultCollapsed?: boolean
  skipTimelineBullet?: boolean
  hidden?: boolean
  onToggle?: (nextCollapsed: boolean) => void
  /**
   * Whether all required fields in the section are valid
   */
  valid?: boolean
}

export function CollapsibleContentSection(props: CollapsibleContentSectionProps) {
  const {
    sectionRef,
    title,
    subtitle,
    ContentComponent,
    ContentElement = null,
    collapsible = true,
    defaultCollapsed = false,
    onToggle,
  } = props
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({ duration: 500 })

  const [collapsed, setCollapsed] = useState(defaultCollapsed ?? false)

  const controls = useMemo(
    () => ({
      open: () => setCollapsed(false),
      close: () => setCollapsed(true),
      toggle: () => setCollapsed(prevState => !prevState),
      scrollIntoView,
    }),
    [],
  )

  useImperativeHandle(sectionRef, () => controls, [])

  return (
    <Paper radius="md" shadow="sm" ref={targetRef}>
      {!!title && (
        <Group
          onClick={() => {
            onToggle?.(!collapsed)
            if (collapsible) controls.toggle()
          }}
          className={theme.cx(classes.groupHeader, collapsible && classes.groupHeaderCollapsible)}>
          <Stack gap={0}>
            <Text size="xl" fw={600}>
              {title}
            </Text>

            {!!subtitle && (
              <Text size="md" c="dimmed">
                {subtitle}
              </Text>
            )}
          </Stack>

          {collapsible && (
            <IconChevronRight
              className={theme.cx([classes.groupHeaderCaret, !collapsed && classes.groupHeaderCaretRotated])}
              stroke={1.5}
            />
          )}
        </Group>
      )}

      <Collapse in={!collapsed}>
        <Box px="lg" py="xl">
          {ContentComponent ? <ContentComponent /> : ContentElement}
        </Box>
      </Collapse>
    </Paper>
  )
}
