export function loadString(key: string): string | undefined {
  try {
    return localStorage.getItem(key) ?? undefined
  } catch {
    return undefined
  }
}

export function saveString(key: string, value: string): boolean {
  try {
    localStorage.setItem(key, value)
    return true
  } catch {
    return false
  }
}

export function load<T>(key: string): T | undefined {
  try {
    const almostThere = localStorage.getItem(key)
    return JSON.parse(almostThere ?? '')
  } catch {
    return undefined
  }
}

export function save<T>(key: string, value: T): boolean {
  try {
    return saveString(key, JSON.stringify(value))
  } catch {
    return false
  }
}

export function remove(key: string): void {
  localStorage.removeItem(key)
}

export function clear(): void {
  localStorage.clear()
}
