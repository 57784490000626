import type { DividerProps, StackProps, TextProps } from '@mantine/core'
import { Box, Divider, Stack, Text } from '@mantine/core'
import type { ReactNode } from 'react'

import { theme } from '@/configs/theme'
import * as classes from '@/styles/InputLabel.css'

interface InputLabelProps extends StackProps {
  label: ReactNode
  description?: string
  withAsterisk?: boolean
  withDivider?: boolean

  TextProps?: TextProps
  DividerProps?: DividerProps
}

export function InputLabel(props: InputLabelProps) {
  const { label, withAsterisk, withDivider, DividerProps, TextProps, description, ...BoxProps } = props

  return (
    <Stack gap={theme.rem(8)} {...BoxProps}>
      <Text {...TextProps} className={theme.cx(classes.text, TextProps?.className)}>
        {label}

        {!!withAsterisk && (
          <Text component="span" aria-hidden="true" className={classes.asterisk}>
            {` *`}
          </Text>
        )}
      </Text>

      {description && (
        <Text c="dimmed" className={classes.description}>
          {description}
        </Text>
      )}

      {withDivider && <Divider {...DividerProps} color="gray.4" />}

      {!description && !withDivider && <Box />}
    </Stack>
  )
}
