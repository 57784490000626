import { Box, Button, Grid, Group, Modal, Textarea } from '@mantine/core'
import { IconDeviceFloppy } from '@tabler/icons-react'

import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import * as commonClasses from '@/styles/componentCommons.css'

export function AddStepDataContentModal(props: CMFormSection) {
  async function createCM() {
    const validation = props.stepForm.validateField('content.text')

    if (validation.hasError) {
      return
    }

    const values = props.stepForm.getValues()

    if (props.stepId) {
      await props.contentUpdateMutation?.mutateAsync({
        step_id: props.stepId,
        text: values.content.text,
      })
    } else {
      const lastStep = props.stepsQuery.data?.[props.stepsQuery.data.length - 1]

      await props.stepCreateMutation.mutateAsync({
        task_id: props.taskId!,
        preceding_step_id: lastStep?.stepId,
        content: values.content.text!,
      })
    }

    props.closeModal()
  }

  const isMutating = props.stepCreateMutation?.isPending || props.contentUpdateMutation?.isPending

  return (
    <>
      <Box className={commonClasses.stickyModalFooterContentWrapper}>
        <Grid gutter="xxxl">
          <Grid.Col span={12}>
            <Textarea
              placeholder="Type your content here..."
              withAsterisk
              autosize
              resize="vertical"
              minRows={10}
              flex={1}
              disabled={isMutating}
              {...props.stepForm.getInputProps('content.text')}
            />
          </Grid.Col>
        </Grid>
      </Box>

      <Modal.Header className={commonClasses.stickyModalFooter}>
        <Group gap="xl">
          <Button leftSection={<IconDeviceFloppy />} onClick={createCM} loading={isMutating}>
            Save Content
          </Button>

          {!isMutating && (
            <Button size="sm" variant="light" color="gray" onClick={() => props.closeModal()}>
              Cancel
            </Button>
          )}
        </Group>
      </Modal.Header>
    </>
  )
}
