import type { TextInputProps } from '@mantine/core'
import type { FormErrors, UseFormReturnType } from '@mantine/form'

import { getObjectKeys } from '@/utils/objects'

export function isFieldsListValid<T extends UseFormReturnType<any>>(
  form: T,
  fields: (keyof T['values'])[],
  errors: FormErrors = form.errors,
): boolean {
  const errorKeys = getObjectKeys(errors)

  return fields.every(f => {
    const v = form.values[f]

    return v !== undefined && v !== null && v !== '' && !errorKeys.includes(f as string)
  })
}

export function getRequiredFieldDescriptionProps<T extends UseFormReturnType<any>>(
  form: T,
  field: keyof T['values'],
  description: string,
): Pick<TextInputProps, 'description' | 'error'> {
  const error = form.errors[field as string]

  return {
    description,
    error: error ? description : undefined,
  }
}
