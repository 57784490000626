import type { ActionIconProps, ButtonProps, ElementProps, TooltipProps } from '@mantine/core'
import { ActionIcon, Button, Tooltip } from '@mantine/core'
import type { IconProps } from '@tabler/icons-react'
import { IconSparkles } from '@tabler/icons-react'
import { forwardRef, useMemo } from 'react'

import { theme } from '@/configs/theme'
import * as classes from '@/styles/AIActionButton.css'

type BaseProps = {
  tooltipDisabled?: boolean
  tooltip?: TooltipProps['label']
  TooltipProps?: TooltipProps
  IconProps?: IconProps
}

interface ActionButtonProps extends ActionIconProps, ElementProps<'button', keyof ActionIconProps> {
  variant: 'action-button'
}

interface RegularButtonProps extends ButtonProps, ElementProps<'button', keyof ButtonProps> {
  variant: 'button'
}

type AIActionButtonProps = (ActionButtonProps | RegularButtonProps) & BaseProps

export function AIActionButton(props: AIActionButtonProps) {
  const { tooltip, tooltipDisabled, TooltipProps, IconProps, variant = 'action-button', ...ButtonProps } = props

  const tooltipLabel = tooltip ?? TooltipProps?.label ?? `Let Sidekick fill this in; you can edit it.`
  const disabled = tooltipDisabled ?? TooltipProps?.disabled ?? false

  const Component = useMemo(() => {
    if (variant === 'action-button') {
      return ActionButton
    } else if (variant === 'button') {
      return RegularButton
    }
  }, [variant])

  if (!Component) {
    return null
  }

  return (
    <Tooltip
      withArrow
      arrowSize={12}
      position="top"
      offset={8}
      color="cyan.8"
      closeDelay={500}
      {...TooltipProps}
      label={tooltipLabel}
      disabled={disabled}
      className={theme.cx(classes.tooltip, TooltipProps?.className)}>
      <Component {...(ButtonProps as any)} IconProps={IconProps} />
    </Tooltip>
  )
}

const ActionButton = forwardRef<any, Omit<ActionButtonProps, 'variant'> & Pick<BaseProps, 'IconProps'>>(
  function ActionButton(props, ref) {
    const { IconProps, ...ActionIconProps } = props

    return (
      <ActionIcon ref={ref} size="xl" radius="sm" variant="gradient" {...ActionIconProps}>
        <IconSparkles
          stroke={1.5}
          {...IconProps}
          className={theme.cx(classes.iconActionButton, IconProps?.className)}
        />
      </ActionIcon>
    )
  },
)

const RegularButton = forwardRef<any, Omit<RegularButtonProps, 'variant'> & Pick<BaseProps, 'IconProps'>>(
  function RegularButton(props, ref) {
    const { IconProps, ...ButtonProps } = props

    return (
      <Button
        ref={ref}
        variant="gradient"
        leftSection={
          <IconSparkles
            stroke={1.5}
            {...IconProps}
            className={theme.cx(classes.iconRegularButton, IconProps?.className)}
          />
        }
        {...ButtonProps}>
        {props.children ?? 'Ask Sidekick'}
      </Button>
    )
  },
)
