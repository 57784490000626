import type { FileWithPath } from '@mantine/dropzone'
import { notifications } from '@mantine/notifications'
import { useState } from 'react'

import { useGlobalState } from '@/hooks/useGlobalState'
import * as commonClasses from '@/styles/componentCommons.css'

type UseFileUploadArgs = {
  onPrepareError?: () => void
  onUploadError?: () => void
  onUploadSuccess?: () => void
}

export function useFileUpload(args?: UseFileUploadArgs) {
  const { api } = useGlobalState()

  const { onPrepareError, onUploadError, onUploadSuccess } = args ?? {}

  const [uploading, setUploading] = useState<boolean>(false)

  async function upload(file: FileWithPath) {
    setUploading(true)

    const prepareResponse = await api.safeRequest(api.uploadPrepare, { fileType: file.type })

    if (!prepareResponse.ok) {
      notifications.show({
        title: 'Something went wrong.',
        message: 'There was an issue uploading the file. Please try again or contact support.',
        color: 'red',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })
      onPrepareError?.()
      setUploading(false)
      return
    }

    const uploadResponse = await api.safeRequest(api.upload, {
      file_path: prepareResponse.data.filePath,
      file_content: file,
    })

    if (!uploadResponse.ok) {
      notifications.show({
        title: 'Something went wrong.',
        message: 'There was an issue uploading the file. Please try again or contact support.',
        color: 'red',
        classNames: commonClasses.notificationFilled,
        position: 'bottom-left',
      })
      onUploadError?.()
      setUploading(false)
      return
    }

    onUploadSuccess?.()

    setUploading(false)

    return prepareResponse.data
  }

  return { upload, uploading }
}
