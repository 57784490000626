import { useContext, useEffect, useState } from 'react'

import { RootStoreContext, setupRootStore } from '@/services/state/helpers/setupRootStore'

let _unsubscribe: undefined | (() => void)

export const useInitialGlobalState = () => {
  const store = useGlobalState()
  const [isGlobalStateRehydrated, setIsGlobalStateRehydrated] = useState(false)

  useEffect(() => {
    _unsubscribe = setupRootStore(store).unsubscribe

    setIsGlobalStateRehydrated(true)

    return () => {
      _unsubscribe?.()
    }
  }, [store])

  return { globalState: store, isGlobalStateRehydrated }
}

export const useGlobalState = () => useContext(RootStoreContext)
