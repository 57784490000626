import type { TextProps } from '@mantine/core'
import { Text } from '@mantine/core'
import type { IconProps } from '@tabler/icons-react'
import { IconBarrierBlock } from '@tabler/icons-react'
import type { ReactNode } from 'react'

interface ComingSoonProps extends TextProps {
  prefix?: ReactNode
  suffix?: ReactNode
  withParens?: boolean
  IconProps?: IconProps
}

export function ComingSoon(props: ComingSoonProps) {
  const { prefix, suffix, withParens = true, IconProps, ...TextProps } = props

  return (
    <>
      {prefix}
      <Text
        tt="uppercase"
        size="xs"
        c={'orange'}
        fw={600}
        lh="inherit"
        component="span"
        {...TextProps}
        style={{ display: 'inline-flex', alignItems: 'center', ...TextProps?.style }}>
        {withParens && '('}
        <IconBarrierBlock size={20} style={{ position: 'relative', top: -1 }} {...IconProps} /> Coming Soon
        {withParens && ')'}
      </Text>
      {suffix}
    </>
  )
}
