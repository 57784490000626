import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import type {
  CreateFAQsParams,
  CreateResourceParams,
  GetContentModuleResponse,
  GetFAQsResponse,
  GetResourcesResponse,
  GetStepsResponse,
  UpdateContentModuleParams,
  UpdateFAQsParams,
  UpdateStepParams,
} from '@/services/api/api.types'
import { logForm } from '@/utils/logs'

/**
 *
 * Schemas
 *
 */
export const contentSchema = z.object({
  // contentId: z.nullable(z.string()), // not needed for now
  file: z.nullable(z.instanceof(File)),
  text: z.string().min(1, "The content can't be blank."), // content: for GET; text: for POST
})

export const moduleSchema = z
  .object({
    taskId: z.nullable(z.string()), // task_id
    name: z.string().min(1), // name
    shortDescription: z.string().min(1), // msk introduction
    presentationMode: z.enum(['sequential', 'member_directed']), // step presentation mode - TODO: cast to boolean for api `are_step_sequential
    access: z.enum(['restricted', 'open']), // privacy setting - NOT IMPLEMENTED
    expirationDate: z.date().optional(), // expiration date - NOT IMPLEMENTED
    dueDate: z.date().optional(), // due date - NOT IMPLEMENTED
    categories: z.array(z.string()).min(1), // categories
    mappings: z.array(z.string()).min(1), // mappings
    selectEsk: z.string(), // esk
    content: contentSchema, // content
  })
  .partial({ expirationDate: true, dueDate: true })

export const faqSchema = z.object({
  faqId: z.nullable(z.string()),
  question: z.string().min(1),
  answer: z.string().min(1, "Answer can't be empty."),
})

export const resourceSchema = z.object({
  resourceId: z.nullable(z.string()),
  data: z.string().min(1),
  dataUrl: z.string().url(),
  file: z.nullable(z.instanceof(File)),
  fileName: z.nullable(z.string()),
  type: z.enum(['url', 'image', 'video', 'text']),
  keywords: z.array(z.string()),
  title: z.string().min(1),
  description: z.string().min(1),
})

export const stepSchema = z.object({
  stepId: z.nullable(z.string()),
  content: contentSchema,
  name: z.string().min(1),
  titleImage: z.nullable(z.string().min(1)),
  introduction: z.string().min(1), // esk introduction
  // shortDescription: z.string().min(1).max(400), // esk reminder
  title: z.string().min(1), // msk reminder
  // deliveryMode: z.nativeEnum(DeliveryMode),
  // access: z.enum(['restricted', 'open']), // privacy setting - NOT IMPLEMENTED
})

/**
 *
 * Form Hooks
 *
 */
export function useContentModuleForm(opts?: { debug?: boolean }) {
  const form = useForm<z.infer<ModuleFormSchema>>({
    mode: 'controlled',
    validate: zodResolver(moduleSchema),
    validateInputOnChange: true,
    initialValues: {
      content: { text: '', file: null },
      ...castContentModuleToZodForm(),
    },
    onValuesChange(values) {
      if (opts?.debug) {
        logForm('Content Module', values)
      }
    },
  })

  return { form, schema: moduleSchema, contentSchema }
}

export function useFAQForm(opts?: { debug?: boolean }) {
  const form = useForm<z.infer<FAQFormSchema>>({
    mode: 'controlled',
    validate: zodResolver(faqSchema),
    validateInputOnChange: true,
    initialValues: castFAQToZodForm(),
    onValuesChange(values) {
      if (opts?.debug) {
        logForm('FAQ', values)
      }
    },
  })

  return { form, schema: faqSchema }
}

export function useResourceForm(opts?: { debug?: boolean }) {
  const form = useForm<z.infer<ResourceFormSchema>>({
    mode: 'controlled',
    validate: zodResolver(resourceSchema),
    validateInputOnChange: true,
    initialValues: castResourceToZodForm(),
    onValuesChange(values) {
      if (opts?.debug) {
        logForm('Resource', values)
      }
    },
  })

  return { form, schema: resourceSchema }
}

export function useStepForm(opts?: { debug?: boolean }) {
  const form = useForm<z.infer<StepFormSchema>>({
    mode: 'controlled',
    validate: zodResolver(stepSchema),
    validateInputOnChange: true,
    initialValues: castStepToZodForm(),
    onValuesChange(values) {
      if (opts?.debug) {
        logForm('Step', values)
      }
    },
  })

  return { form, schema: stepSchema }
}

/**
 *
 * Data Transformation
 *
 */
export function castContentModuleToZodForm(
  data?: Partial<GetContentModuleResponse>,
): Omit<z.infer<ModuleFormSchema>, 'resource' | 'content' | 'step'> {
  return {
    taskId: data?.taskId ?? null,
    name: data?.name ?? '',
    shortDescription: data?.shortDescription ?? '',
    presentationMode: data?.areStepSequential
      ? moduleSchema.shape.presentationMode.enum.sequential
      : moduleSchema.shape.presentationMode.enum.member_directed,
    selectEsk: data?.selectEsk ?? '',
    categories: data?.categories ?? [],
    mappings: data?.mappings ?? [],

    // mock
    access: 'open',
  }
}

export function castResourceToZodForm(data?: Partial<GetResourcesResponse[0]>): z.infer<ResourceFormSchema> {
  return {
    resourceId: data?.resourceId ?? null,
    title: data?.title ?? '',
    description: data?.description ?? '',
    file: null,
    data: data?.data ?? '',
    dataUrl: data?.type === 'url' ? (data?.data ?? '') : '',
    fileName: data?.fileName ?? '',
    type: data?.type ?? 'text',
    keywords: data?.keywords ?? [],
  }
}

export function castFAQToZodForm(data?: Partial<GetFAQsResponse['0']>): z.infer<FAQFormSchema> {
  return {
    faqId: data?.faqId ?? null,
    question: data?.question ?? '',
    answer: data?.answer ?? '',
  }
}

export function castZodFormToContentModule(
  data: z.infer<ModuleFormSchema>,
): Omit<UpdateContentModuleParams, 'task_id'> {
  return {
    name: data.name,
    title: data.name,
    short_description: data.shortDescription,
    are_step_sequential: data.presentationMode === undefined ? undefined : data.presentationMode === 'sequential',
    select_esk: data.selectEsk,
    categories: data.categories,
    mappings: data.mappings,
    long_description: 'NOT_IMPLEMENTED_FRONT_END',
  }
}

export function castZodFormToResource(
  data: z.infer<ResourceFormSchema>,
): Omit<CreateResourceParams, 'task_id' | 'step_id'> {
  return {
    type: data.type,
    data: data.type === 'url' ? data.dataUrl : data.data,
    title: data.title,
    description: data.description,
    keywords: data.keywords,
    file: data.file ?? undefined,
    file_name: data.fileName,
  }
}

export function castZodFormToFAQsUpdate(data: z.infer<FAQFormSchema>): Omit<UpdateFAQsParams, 'task_id' | 'step_id'> {
  return {
    faqs: [
      {
        question: data.question,
        answer: data.answer,
        faq_id: data.faqId!,
      },
    ],
  }
}

export function castZodFormToFAQsCreate(data: z.infer<FAQFormSchema>): Omit<CreateFAQsParams, 'task_id' | 'step_id'> {
  return {
    faqs: [
      {
        question: data.question,
        answer: data.answer,
      },
    ],
  }
}

export function castStepToZodForm(data?: Partial<GetStepsResponse[0]>): z.infer<StepFormSchema> {
  return {
    stepId: data?.stepId ?? null,
    content: { text: '', file: null },
    name: data?.name ?? '',
    titleImage: data?.titleImage ?? '',
    introduction: data?.introduction ?? '',
    // shortDescription: data?.shortDescription ?? '',
    title: data?.title ?? '',
    // deliveryMode: data?.deliveryMode ?? stepSchema.shape.deliveryMode.enum.Paraphrase,

    // mock
    // access: 'open',
  }
}

export function castZodFormToStep(data: z.infer<StepFormSchema>): Omit<UpdateStepParams, 'step_id'> {
  return {
    name: data.name,
    title_image: data.titleImage ?? '',
    introduction: data.introduction,
    short_description: data.title,
    title: data.title,
    // delivery_mode: data.deliveryMode,
  }
}

export type ModuleFormSchema = typeof moduleSchema
export type FAQFormSchema = typeof faqSchema
export type ResourceFormSchema = typeof resourceSchema
export type ContentFormSchema = typeof contentSchema
export type StepFormSchema = typeof stepSchema
