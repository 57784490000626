import { Box, Button, Grid, Group, Modal, Textarea } from '@mantine/core'
import { IconDeviceFloppy } from '@tabler/icons-react'
import type { z } from 'zod'

import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import * as commonClasses from '@/styles/componentCommons.css'
import type { FAQFormSchema } from '@/utils/contentModuleForm'
import { castFAQToZodForm, castZodFormToFAQsCreate, castZodFormToFAQsUpdate } from '@/utils/contentModuleForm'
import { getRequiredFieldDescriptionProps } from '@/utils/forms'

export type FAQModalProps = {
  type: 'cm' | 'step' | 'ai'
  onSubmit?: (faq: z.infer<FAQFormSchema>) => void
}

export function FAQModal(props: CMFormSection & FAQModalProps) {
  const values = props.faqForm.getValues()
  const validate = props.faqForm.validateField

  const isNew = !values.faqId

  async function submitFAQ(opts?: { addMore?: boolean }) {
    const { addMore = false } = opts ?? {}

    const validations = [validate('question'), validate('answer')]

    if (validations.some(v => v.hasError)) {
      return
    }

    const idParams = props.type === 'cm' ? { task_id: props.taskId! } : { step_id: props.stepId! }

    if (props.type === 'ai') {
      props.onSubmit?.(values)
    } else if (!isNew) {
      // update
      const params = castZodFormToFAQsUpdate(values)
      await props.faqsUpdateMutation?.mutateAsync({ ...params, ...idParams })
    } else {
      // create
      const params = castZodFormToFAQsCreate(values)
      await props.faqsCreateMutation?.mutateAsync({ ...params, ...idParams })
    }

    if (addMore) {
      props.faqForm.setValues(castFAQToZodForm())

      return
    }

    props.closeModal()
  }

  const isMutating = props.faqsCreateMutation?.isPending || props.faqsUpdateMutation?.isPending

  return (
    <>
      <Box className={commonClasses.stickyModalFooterContentWrapper}>
        <Grid gutter="xxxl">
          <Grid.Col span={6}>
            <Textarea
              withAsterisk
              label="Question"
              autosize
              resize="vertical"
              minRows={8}
              flex={1}
              {...props.faqForm.getInputProps('question')}
              {...getRequiredFieldDescriptionProps(
                props.faqForm,
                'question',
                'Question that a user may have about this content.',
              )}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <Textarea
              withAsterisk
              label="Answer"
              autosize
              resize="vertical"
              flex={1}
              minRows={8}
              {...props.faqForm.getInputProps('answer')}
            />
          </Grid.Col>
        </Grid>
      </Box>

      <Modal.Header className={commonClasses.stickyModalFooter}>
        <Group gap="xl">
          <Button leftSection={<IconDeviceFloppy />} onClick={() => submitFAQ()} loading={isMutating}>
            Save and Exit
          </Button>

          {isNew && props.type !== 'ai' && (
            <Button
              variant="outline"
              leftSection={<IconDeviceFloppy />}
              onClick={() => submitFAQ({ addMore: true })}
              loading={isMutating}>
              Save and Add More
            </Button>
          )}

          {!isMutating && (
            <Button size="sm" variant="light" color="gray" onClick={() => props.closeModal()}>
              Cancel
            </Button>
          )}
        </Group>
      </Modal.Header>
    </>
  )
}
