import { Timeline } from '@mantine/core'
import type { IconProps } from '@tabler/icons-react'
import {
  IconNumber1,
  IconNumber2,
  IconNumber3,
  IconNumber4,
  IconNumber5,
  IconNumber6,
  IconNumber7,
} from '@tabler/icons-react'
import type { ComponentType } from 'react'
import { useMemo } from 'react'

import { theme } from '@/configs/theme'
import * as classes from '@/styles/SectionedForm.css'

import type { CollapsibleContentSectionProps } from './CollapsibleContentSection'
import { CollapsibleContentSection } from './CollapsibleContentSection'

export type SectionedFormProps = {
  sections: CollapsibleContentSectionProps[]
}

const iconRegistry = {
  1: IconNumber1,
  2: IconNumber2,
  3: IconNumber3,
  4: IconNumber4,
  5: IconNumber5,
  6: IconNumber6,
  7: IconNumber7,
}

export function SectionedForm(props: SectionedFormProps) {
  const { sections } = props

  let activeIndex = sections.findIndex(s => s.valid === false)
  activeIndex = activeIndex === -1 ? sections.length - 1 : activeIndex

  return (
    <Timeline active={activeIndex} bulletSize={36} lineWidth={3}>
      {sections.map((s, index, arr) => {
        const skipCount = arr.slice(0, index).filter(s => s.skipTimelineBullet || s.hidden).length

        const Icon: ComponentType<IconProps> = useMemo(
          () => iconRegistry[(index - skipCount + 1) as keyof typeof iconRegistry],
          [skipCount],
        )

        if (s.hidden) return null

        return (
          <Timeline.Item
            key={s.id}
            bullet={<Icon size={18} />}
            data-timeline-item-hidden={s.hidden}
            classNames={{
              item: classes.item,
              itemBullet: theme.cx(classes.itemBullet, s.skipTimelineBullet && classes.itemBulletHidden),
            }}>
            <CollapsibleContentSection {...s} />
          </Timeline.Item>
        )
      })}
    </Timeline>
  )
}
